import { Prefs3D, Prefs } from "./PreferenceNames";
import { Preferences } from "./Preferences";
import { Profile } from "./Profile";

/**
 * @callback FeatureFlagChangeListener
 * @param {boolean} value - The new value of the feature flag.
 * @private
 */

/**
 * @typedef {Object} FeatureFlagInitializationData
 * @property {*} [preferenceOverrideValue] - If provided, the value should be used for initialization instead of the preference value.
 * @property {Preferences} preferences - Shared global preferences.
 * @private
 */

/**
 * @callback FeatureFlagInitializationCallback
 * @param {boolean} value - The feature flag value.
 * @param {FeatureFlagInitializationData} initializationData - Data provided to the initialization callback.
 * @returns {Promise<boolean>} True if the initialization was successful, otherwise false.
 * @private
 */

/**
 * Static class that manages feature flags. Feature flags are used to enable or disable certain
 * features of the viewer. After initialization, the flags become immutable.
 *
 * @example
 * FeatureFlags.set('EXAMPLE_FEATURE', true);
 */
export class FeatureFlags {

    static #flagsByName = new Map();
    static #initialized = false;

    /**
     * Adds a new feature flag. If a flag with that name already exists, adding is skipped.
     *
     * @param {string} name
     * @param {boolean} value - Default value of the feature flag. True for enabled, false for disabled.
     *
     * @private
     */
    static _add(name, value) {
        if (this.#initialized) {
            console.warn(`Feature flags cannot be added after initialization.`);
            return;
        } else if (this.#flagsByName.has(name)) {
            console.warn(`Feature flag '${name}' already exists.`);
            return;
        } else {
            this.#flagsByName.set(name, {
                value,
                initializationCB: undefined,  // FeatureFlagInitializationCallback
                changeListener: undefined,    // FeatureFlagChangeListener
            });
        }
    }

    /**
     * Registers a callback to be executed on global initialization during Autodesk.Viewing.Initializer execution.
     * The callback receives the feature flag value, global preferences and other provided initialization data.
     * If the flag does not exist, nothing happens. This is only allowed before initialization and intended for
     * some flags to run initialization code.
     *
     * @param {string} name - Feature flag identifier
     * @param {FeatureFlagInitializationCallback} callback - Initialization callback.
     *
     * @private
     */
    static _registerInitializationCallback(name, callback) {
        const flag = this.#flagsByName.get(name);
        if (flag === undefined) {
            console.warn(`Feature flag '${name}' not found; callback registration is skipped.`);
            return;
        }
        flag.initializationCB = callback;
    }

    /**
     * Registers a callback to be executed when the feature flag is changed. The callback receives the new value of the
     * feature flag. If the flag does not exist, nothing happens.
     *
     * @param {string} name - Feature flag identifier
     * @param {FeatureFlagChangeListener} callback - Change callback to be executed when the flag is changed.
     *
     * @private
     */
    static _registerChangeListener(name, callback) {
        const flag = this.#flagsByName.get(name);
        if (flag === undefined) {
            console.warn(`Feature flag '${name}' not found; callback registration is skipped.`);
            return;
        }
        flag.changeListener = callback;
    }

    /**
     * Sets the value of a given feature flag. If the flag does not exist, nothing happens.
     *
     * @param {string} name - Feature flag identifier
     * @param {boolean} value - New value of the feature flag
     */
    static set(name, value) {
        const flag = this.#flagsByName.get(name);
        if (this.#initialized) {
            console.warn(`Feature flag '${name}' is immutable and cannot be modified after initialization.`);
        } else if (flag === undefined) {
            console.warn(`Feature flag '${name}' not found; modification is skipped.`);
        } else {
            const valueChanged = flag.value !== value;

            if (flag.value !== value) flag.value = value;

            if (flag.changeListener !== undefined && valueChanged) {
                flag.changeListener(flag.value);
            }
        }
    }

    /**
     * Returns whether a given feature flag is enabled.
     * @param {string} name - Feature flag identifier
     * @returns {boolean|undefined} Feature flag value or undefined if the flag does not exist.
     */
    static isEnabled(name) {
        const flag = this.#flagsByName.get(name);
        return flag?.value;
    }

    static _setInitializationData(name, initializationData) {
        const flag = this.#flagsByName.get(name);

        if (flag === undefined) {
            console.warn(`Feature flag '${name}' not found; modification is skipped.`);
            return;
        }

        if (this.#initialized) {
            console.warn(`Feature flag '${name}' has already been initialized. Ignoring initialization data.`);
            return;
        }

        flag.initializationData = initializationData;
    };

    static _getInitializationData(name) {
        const flag = this.#flagsByName.get(name);

        if (flag === undefined) {
            console.warn(`Feature flag '${name}' not found; returning undefined.`);
            return;
        }

        return flag.initializationData;
    };

    /**
     * Initializes all feature flags in the global context independent of a viewer instance. This method is intended to
     * be called once within the global Autodesk.Viewing.Initializer only, not anywhere else.
     *
     * @private
     */
    static async _initialize() {
        if (this.#initialized) return;

        this.#initialized = true;

        // Prepare default profile settings and preferences.
        const defaultProfile = new Profile(Autodesk.Viewing.ProfileSettings.Default);
        const preferences = new Preferences();
        defaultProfile.apply(preferences);

        // For each flag, check availability and run initialization callback if available.
        const initializationPromises = new Array();
        for (const [ , flag ] of this.#flagsByName) {
            Object.freeze(flag);

            const initializationCB = flag.initializationCB;
            if (initializationCB) {
                initializationPromises.push(initializationCB(flag.value, { preferences, customInitializationData: flag.initializationData }));
            }
        };
        return Promise.all(initializationPromises);
    }

    /**
     * Prints all public feature flags and their values to the console
     */
    static print() {
        console.log(`Feature Flags:${this.#flagsByName.size === 0 ? ' none' : ''}`);
        for(const [ name, { value } ] of this.#flagsByName) {

            // Skip internal feature flags
            if (Object.values(InternalFeatureFlags).includes(name)) {
                continue;
            }

            console.log(`  - ${name}: ${value}`);
        };
    }
};

/**
 * List of publicly available feature flags.
 */
export const PublicFeatureFlags = Object.freeze({
    LargeModelExperienceOptOut: 'HIDE_LARGE_MODEL_EXPERIENCE',
    EnableSpotMeasurement: 'ENABLE_LOCATION_MEASUREMENT',
});

FeatureFlags._add(PublicFeatureFlags.LargeModelExperienceOptOut, false);
FeatureFlags._registerInitializationCallback(PublicFeatureFlags.LargeModelExperienceOptOut, (value, initializationData) => {
    if (!value && (initializationData.customInitializationData?.overridePreferenceValue ?? initializationData.preferences.get(Prefs3D.LARGE_MODEL_EXPERIENCE))) {
        return Autodesk.Viewing.Private.useHLOD();
    }});

FeatureFlags._add(PublicFeatureFlags.EnableSpotMeasurement, false);

/**
 * Features in early development, internal and not intended to be exposed to any autodesk namespace.
 * @private
 */
export const InternalFeatureFlags = Object.freeze({
    OutOfCoreGpuMemoryManagement: 'OUT_OF_CORE_GPU_MEMORY_MANAGEMENT',
    WebGPUBackend: 'ENABLE_WEBGPU',
});

// Decide whether we are going to initialize WebGPU on startup, depending on
//  - Feature flag (whether toggle is enabled)
//  - Preference value (whether user has enabled WebGPU in the settings)
//  - Override value (enforced value by the app - currently only used for testing)
const isWebGPUWanted = (toggleEnabled, { preferences, customInitializationData }) => {
    // Allow page to force-override the settings toggle (currently only used for testing).
    const prefOverrideValue = customInitializationData?.overridePreferenceValue;
    return toggleEnabled && (prefOverrideValue ?? preferences.get(Prefs.WEBGPU));
};

FeatureFlags._add(InternalFeatureFlags.WebGPUBackend, false);
FeatureFlags._registerInitializationCallback(InternalFeatureFlags.WebGPUBackend, async (value, initializationData) => {
    if (isWebGPUWanted(value, initializationData)) {
        return await Autodesk.Viewing.Private.useWebGPU();
    }
});

FeatureFlags._add(InternalFeatureFlags.OutOfCoreGpuMemoryManagement, true);
FeatureFlags._registerInitializationCallback(InternalFeatureFlags.OutOfCoreGpuMemoryManagement, (value, initializationData) => {

    // TODO: The "skip for WebGPU" section below will be removed once we combined WebGPU and OutOfCoreGpuMemoryManagement.
    //
    // WebGPU doesn't work with out-of-core yet. So, we stop here if we are going to start with WebGPU.
    // Note...
    //  1. We cannot safely use the USE_WEBGPU toggle at this point yet, because it dependends on if/how-far the initializer of the WebGPU flag was already run.
    //     Therefore, we rather check if "WebGPU" is wanted, which is independent of timing/order of flag initializations.
    //  2. Note that we can only take the shared prefs object from initalizationData, but the initializionData will not know about customInitializationData for WebGPU flag.
    const webGPUFlagEnabled = FeatureFlags.isEnabled(InternalFeatureFlags.WebGPUBackend);
    if (webGPUFlagEnabled) { // This check is primarily to make easy to prove/see that this change cannot have any side-effects if WebGPU feature flag is off
        const preferences          = initializationData.preferences;
        const webGPUCustomInitData = FeatureFlags._getInitializationData(InternalFeatureFlags.WebGPUBackend);
        const webGPUOn             = isWebGPUWanted(webGPUFlagEnabled, { preferences, customInitializationData: webGPUCustomInitData });
        if (webGPUOn) {
            return;
        }
    }

    // Only activate when HLOD is enabled (which means this will be toggled by the same user preferences setting,
    // because the HLOD preference is evaluated above).
    if (value && Autodesk.Viewing.Private.USE_HLOD) {
        Autodesk.Viewing.Private.enableOutOfCoreTileManager();
    }
});
