import { pathToURL, ViewingService } from "../../net/Xhr";
import { logger } from "../../../logger/Logger";
import { initPlacement, transformAnimations, transformCameraData } from "../common/SvfPlacementUtils";
import { bin16ToPackedString, unpackHexString } from './HashStrings';
import { LmvVector3 } from '../../../wgs/scene/LmvVector3';
import { MeshFlags } from '../../../wgs/scene/MeshFlags';
import { LmvMatrix4 } from '../../../wgs/scene/LmvMatrix4';
import { allocateUintArray } from "../../../wgs/scene/IntArray";
import { ProgressiveReadContext } from "./ProgressiveReadContext";
import { getGlobal } from "../../../global";
import { PackFileReader } from '../common/PackReader';
import { readViewpointDefinition } from '../common/Viewpoints';
import { readOverrideSet } from '../common/OverrideSets';
import { readNamedItemTree } from '../common/NamedItemTree';

export const FLUENT_URN_PREFIX = "urn:adsk.fluent:";
export const DS_OTG_CDN_PREFIX = "$otg_cdn$";


export function OtgPackage() {


	this.materials = null; //The materials json as it came from the SVF

	this.fragments = null; //will be wrapped in a FragmentList

	this.geompacks = [];

	this.propertydb = {
		attrs : [],
		avs: [],
		ids: [],
		values: [],
		offsets: [],

		// Table to map between Svf and Otg ids.
		// Note that the same db might be reused by a 2D sheet later. So, it's important to include dbid
		// here as well, even if we don't use it for 3D.
		dbid: [] 
	};

	this.bbox = null; //Overall scene bounds

	this.animations = null; // animations json

	this.globalOffset = { x: 0, y: 0, z: 0 };

	this.pendingRequests = 0;
	this.initialLoadProgress = 0;
	this.viewpointProgress = 0;

	this.abortController = new AbortController();

	this.viewpoints = [];
	this.overrideSets = [];
	this.extraMatHashes = {};

	// Caches instance count per geometry for the loaded model independent of whether the geometry is actually loaded 
	this.geometryInstanceCounts = null;


	this.extraFragInfo = null;
}

/**
 * @param {number} materialIndex The index of the material
 * @returns {null|string} Returns the hash or null if invalid index, or the hashlist hasn't been loaded
 */
OtgPackage.prototype.getMaterialHash = function(materialIndex) {

	if (!this.materialHashes?.hashes)
		return null;
	var matHash = this.materialHashes.hashes[materialIndex];

	if (!matHash?.length) return null;

	return matHash;
};

/**
 * Generate material hashes for materials in override set. 
 * Get the material indexes from overrideSet and 
 * check if they are not already loaded (i.e not added via fragments).
 */
OtgPackage.prototype.getExtraMaterialHashes = async function () {

	let matIndexSet = new Set();
	this.overrideSets?.forEach( overrideSet => {
		const {defaultMaterialIndex, materialOverrides} = overrideSet;
		if (defaultMaterialIndex) 
            matIndexSet.add(defaultMaterialIndex);

		materialOverrides?.forEach((matArrayItem) => {
			// matArrayItem = {dbId:'' , materialIndex: ''}
			matIndexSet.add(matArrayItem.materialIndex); // Get the values for materialIndex only
		});
	});

	// Loading of viewpoint runs asynchronously, so we need to wait for the material hashes to be initialized
	// before we can access them below.
	await this.materialHashes.initialized;

	const extraMatIds = [...matIndexSet].filter(x => !this.materialHashes.hashes[x]);

	if (extraMatIds.length) {
		this.needExtraMaterials = true;
	}

	// get the hash string that points to the material
	for (const extraMaterialIndex of extraMatIds) {
		const matHash = this.getMaterialHash(extraMaterialIndex);
		if (matHash) this.extraMatHashes[extraMaterialIndex] = matHash;
	}
};

/**
 * @param {number} geomIndex Geometry index
 * @returns {null|string} Returns the hash or null if invalid index, or the hashlist hasn't been loaded
 */
OtgPackage.prototype.getGeometryHash = function (geomIndex) {

	if (!this.geomMetadata?.hashes) return null;

	var gHash = this.geomMetadata.hashes[geomIndex];

	if (!gHash?.length) return null;

	return gHash;
};

/**
 * Returns the number of instances of the geometry used by the given fragment.
 * In contrast to the instanceCount in GeometryList, this function also works for
 * geometries that have not yet been loaded and thus returns an accurate count once the
 * full fragment list is available. In contrast, the counts in GeometryList are only
 * available once the geometry that has been loaded.
 * 
 * @param {number} fragId - id of the fragment
 * @returns {number} - number of instances of the geometry used by the given fragment
 */
OtgPackage.prototype.getFragInstanceCount = function(fragId) {
	if (!this.geometryInstanceCounts) {
		this.geometryInstanceCounts = allocateUintArray(this.fragments.geomDataIndexes.length, this.metadata.stats.num_geoms);
		for (let i = 0; i < this.fragments.geomDataIndexes.length; i++) {
			this.geometryInstanceCounts[this.fragments.geomDataIndexes[i]] += 1;
		}
	};

	return this.geometryInstanceCounts[this.fragments.geomDataIndexes[fragId]];
};

//Set up the fragments, materials, and meshes lists
//which get filled progressively as we receive their data
OtgPackage.prototype.initEmptyLists = function(loadContext) {

	var svf = this;

	var frags = svf.fragments = {};
	frags.length = svf.metadata.stats.num_fragments;
	frags.numLoaded = 0;
	frags.boxes = loadContext.fragmentTransformsDouble ? new Float64Array(frags.length*6) : new Float32Array(frags.length*6);
	frags.transforms = loadContext.fragmentTransformsDouble ? new Float64Array(frags.length * 12) : new Float32Array(frags.length * 12);
	frags.materials = allocateUintArray(frags.length, svf.metadata.stats.num_materials);
	frags.geomDataIndexes = allocateUintArray(frags.length, svf.metadata.stats.num_geoms);
	frags.fragId2dbId = new Int32Array(frags.length);
	frags.visibilityFlags = new Uint8Array(frags.length);
	frags.topoIndexes = null;

	if (loadContext.buildDbId2FragIdMap) {
		// Keys are dbIds, values are fragIds or (if multiple fragIds share the same dbId) arrays of fragIds
		frags.dbId2fragId = {};
	}

	svf.geomMetadata = {
		hashes : null,
		byteStride: 0,
		version: 0,
		numLoaded: 0,
		hashToIndex: new Map()
	};

	this._materialHashesInitializedResolve = null;
	svf.materialHashes = {
		hashes: null,
		byteStride: 0,
		version: 0,
		numLoaded: 0,
		initialized: new Promise((resolve) => this._materialHashesInitializedResolve = resolve)
	};

	//Shell object to make it compatible with SVF.
	//Not sure which parts of this are really needed,
	//SceneUnit is one.
	svf.materials = {
		"name":	"LMVTK Simple Materials",
		"version":	"1.0",
		"scene":	{
			"SceneUnit":	8215,
			"YIsUp":	0
		},
		materials: {}
	};


};


OtgPackage.prototype.loadAsyncResource = function(loadContext, resourcePath, responseType, callback) {

	//Launch an XHR to load the data from external file
	var svf = this;

	this.pendingRequests ++;

	function xhrCB(responseData) {
		svf.pendingRequests--;

		callback(responseData);
	}

	resourcePath = pathToURL(resourcePath, loadContext.basePath);

	ViewingService.getItem(loadContext, resourcePath, xhrCB, loadContext.onFailureCallback,
		{ responseType:responseType || "arraybuffer" }
	);

};


OtgPackage.prototype.loadAsyncProgressive = function(loadContext, resourcePath, ctx, resourceName, onFailureCallback) {

	var svf = this;

	resourcePath = pathToURL(resourcePath, loadContext.basePath);

	function onDone() {
		svf.postLoad(loadContext, resourceName);
	}

	function onData(chunk) {
		ctx.onData(chunk);
	}

	ViewingService.getItem(loadContext, resourcePath, onDone, onFailureCallback || loadContext.onFailureCallback,
		{
			responseType: 'arraybuffer',
			ondata: onData,
			abortSignal: svf.abortController.signal,
			useFetch: true
		}
	);

};


OtgPackage.prototype.loadMetadata = function(loadContext, path) {

	var svf = this;

	return new Promise((resolve) => {
		this.loadAsyncResource(loadContext, path, "json", function(data) {

			//For OTG, there is a single JSON for metadata and manifest,
			//and it's the root
			svf.metadata = data;
			svf.manifest = svf.metadata.manifest;

			// Set numGeoms. Note that this must happen before creating the GeometryList.
			svf.numGeoms = svf.metadata.stats.num_geoms;

			svf.processViewPointData(loadContext);

			svf.processMetadata(loadContext);

			svf.initEmptyLists(loadContext);

			var manifest = svf.metadata.manifest;

			//Add the shared property db files to the property db manifest
			//TODO: this is a bit hacky and hardcoded
			var spdb = manifest.shared_assets.pdb;
			for (let p in spdb) {
				if (svf.propertydb[p])
					svf.propertydb[p].push({ path: spdb[p], isShared:true});
				else {
					//Skip this property db file from the list,
					//we don't know how to handle it.
				}

			}

			var pdb = manifest.assets.pdb;
			for (let p in pdb) {
				if (svf.propertydb[p])
					svf.propertydb[p].push({path: pdb[p], isShared:false});
				else {
					//Skip this property db file from the list,
					//we don't know how to handle it.
				}
			}

			//Optional resources

			if (manifest.assets.animations) {
				svf.loadAsyncResource(loadContext, manifest.assets.animations, "json", function(data) {
					svf.animations = data;
					transformAnimations(svf);
				});
			}

			if (manifest.assets.topology) {
				svf.loadAsyncResource(loadContext, path, "json", function(data) {
					svf.topology = data;
				});
			}

			loadContext.onLoaderEvent("otg_root");
			svf.postLoad(loadContext, "metadata");

			resolve();
		});
	});

};

OtgPackage.prototype.addDbIdReference = function(fragId, dbId) {

	const frags = this.fragments;

	// Elements can be either a single fragId or an array of fragIds
	let fid = frags.dbId2fragId[dbId];
	if (fid === undefined) {
		frags.dbId2fragId[dbId] = fragId;
	} else {
		if (typeof fid === "number") {
			frags.dbId2fragId[dbId] = [fid, fragId];
		} else {
			fid.push(fragId);
		}
	}
};

OtgPackage.prototype.loadFragmentList = function(loadContext, path) {

	var svf = this;

	var _t = new LmvVector3();
	var _s = new LmvVector3();
	var _q = { x:0, y:0, z:0, w:1 };
	var _m = new LmvMatrix4(true);
	var _zero = { x:0, y:0, z:0 };

	function readOneFragment(ctx, offset, i) {

		//The index is 1-based (due to the record at index 0 being the file header
		//while the runtime FragmentList is a classic 0-based array, so we subtract 1 here.
		i -= 1;

		//Fragments have to wait for the metadata (placement transform)
		//before they can be fully processed
		if (!svf.metadata)
			return false;

		var idata = ctx.idata();
		var fdata = ctx.fdata();
		offset = offset / 4;

		var frags = svf.fragments;
		var meshid = frags.geomDataIndexes[i] = idata[offset];
		var materialId = frags.materials[i] =   idata[offset+1];
		var dbId = frags.fragId2dbId[i] =       idata[offset+2];
		var flags =                             idata[offset+3];

        // Build reverse mapping from dbId to fragId
        if (loadContext.buildDbId2FragIdMap) {
            svf.addDbIdReference(i, dbId);
        }

		//check if the fragment's material and geometry hashes are already known
		//If not, then pause fragment processing until they are
		//NOTE: mesh ID and material ID are 1-based indices.
		if (meshid > svf.geomMetadata.numLoaded
		|| materialId > svf.materialHashes.numLoaded) {
			//console.log("Delayed fragment", i);
			return false;
		}

		//Decode mesh flags (currently, we only support bitvalue 1 to mark hidden-by-default meshes)
		//If a filter is specified, set the "unloaded" flag to prevent the mesh geometry from being loaded up front
		var wantLoad = svf.dbIdFilter ? svf.dbIdFilter[dbId] : true;
		var hideBit;
		if (!wantLoad) {
			hideBit = MeshFlags.MESH_NOTLOADED;
		} else {
			hideBit = (flags & 1) ? MeshFlags.MESH_HIDE : 0;
		}
		frags.visibilityFlags[i] = hideBit;

		var lo = svf.metadata.fragmentTransformsOffset || _zero;

		//Read the fragment transform
		var to = offset + 4;
		_t.set(fdata[to+0] + lo.x, fdata[to+1] + lo.y, fdata[to+2] + lo.z);
		_q.x = fdata[to+3];
		_q.y = fdata[to+4];
		_q.z = fdata[to+5];
		_q.w = fdata[to+6];
		_s.set(fdata[to+7], fdata[to+8], fdata[to+9]);

		_m.compose(_t, _q, _s);

		if (svf.placementWithOffset) {
			_m.multiplyMatrices(svf.placementWithOffset, _m);
		}

		var e = _m.elements;
		var dst = frags.transforms;
		var off = i * 12;
		dst[off+0] = e[0]; dst[off+1] = e[1]; dst[off+2] = e[2];
		dst[off+3] = e[4]; dst[off+4] = e[5]; dst[off+5] = e[6];
		dst[off+6] = e[8]; dst[off+7] = e[9]; dst[off+8] = e[10];
		dst[off+9] = e[12]; dst[off+10] = e[13]; dst[off+11] = e[14];
		
		// If we obtained the exact bboxes from BVHWorker already, don't overwrite them.
		if (!frags.dontComputeBoxes) {
			//Estimated bounding box based on known unit box for the mesh, and the fragment's world transform
			// These are just used temporarily for early rendering until we get the exact bboxes are obtained from BVHWorker.
			// The estimated mostly work, but may be too large in some cases where the PCA axes do not match those of the minimum oriented bbox.

			let dstBoxes = frags.boxes;
			let offBoxes = i * 6;

			// This is LmvBox3.applyMatrix4, but inlined and much simplified
			for (let j = 0; j < 3; j++) {
				let max = Math.abs(e[j]); // these three lines are the inner loop unrolled
				max += Math.abs(e[j + 4]); // think '* 0.5' for the unit box, but we do that once at the end
				max += Math.abs(e[j + 8]); // also we accumulate only max, min is -max
				max *= 0.5;
				let trans = e[12 + j];
				dstBoxes[offBoxes + j] = trans - max; // min is -max
				dstBoxes[offBoxes + j + 3] = trans + max;
			}
		}

		frags.numLoaded = i+1;

		loadContext.onLoaderEvent("fragment", i);

		return true;
	}


	let ctx = new ProgressiveReadContext(readOneFragment);
	this.loadAsyncProgressive(loadContext, path, ctx, "all_fragments");

	return ctx;
};

OtgPackage.prototype.handleHashListRequestFailures = function(loadContext) {

	if (!this.metadata) {
		// Model root not known yet => Retry later when model root is available
		return;
	}

	// If the model is empty, the hash list files don't exist by design.
	// For this case, we suppress the request failure error.
	if (this.geomHashListFailure && this.metadata.stats.num_geoms > 0) {
		loadContext.onFailureCallback.apply(loadContext, this.geomHashListFailure);
	}

	if (this.matHashListFailure && this.metadata.stats.num_materials > 0) {
		loadContext.onFailureCallback.apply(loadContext, this.matHashListFailure);
	}

	this.geomHashListFailure = null;
	this.matHashListFailure  = null;
};

OtgPackage.prototype.loadGeometryHashList = function(loadContext, path) {

	var svf = this;

	function readOneHash(ctx, offset, i) {

		//have ot wait for the metadata
		//before they can be fully processed
		if (!svf.metadata)
			return false;

		svf.initializeGeometryHashList(ctx.byteStride(), ctx.version());

		svf.geomMetadata.hashes[i] = bin16ToPackedString(ctx.i16data(), offset / 2);

		svf.geomMetadata.numLoaded = i;

		return true;
	}

	// Hash list request error handling is deferred until model root is known
	var onFailure = function() {
		svf.geomHashListFailure = arguments;
		svf.handleHashListRequestFailures(loadContext);
		svf.postLoad(loadContext, "geometry_ptrs", ctx); // Finish hash-list loading state
	};

	let ctx = new ProgressiveReadContext(readOneHash, 20);
	this.loadAsyncProgressive(loadContext, path, ctx, "geometry_ptrs", onFailure);

	return ctx;
};

OtgPackage.prototype.initializeGeometryHashList = function(byteStride, version) {
	if (!this.geomMetadata.hashes) {
		this.geomMetadata.hashes = new Array(this.numGeoms + 1);
		this.geomMetadata.byteStride = byteStride;
		this.geomMetadata.version = version;
	}
};

OtgPackage.prototype.loadMaterialHashList = function(loadContext, path) {

	var svf = this;

	function readOneHash(ctx, offset, i) {

		//have ot wait for the metadata
		//before they can be fully processed
		if (!svf.metadata)
			return false;

		svf.initializeMaterialHashList(ctx.byteStride(), ctx.version());

		svf.materialHashes.hashes[i] = bin16ToPackedString(ctx.i16data(), offset / 2);

		svf.materialHashes.numLoaded = i;

		return true;
	}

	// Hash list request error handling is deferred until model root is known
	var onFailure = function() {
		svf.matHashListFailure = arguments;
		svf.handleHashListRequestFailures(loadContext);
		svf.postLoad(loadContext, "material_ptrs", ctx); // Finish hash-list loading state
	};

	let ctx = new ProgressiveReadContext(readOneHash, 20);
	this.loadAsyncProgressive(loadContext, path, ctx, "material_ptrs", onFailure);

	return ctx;
};

OtgPackage.prototype.initializeMaterialHashList = function(byteStride, version) {
	if (!this.materialHashes.hashes) {
		this.numMaterials = this.metadata.stats.num_materials;
		this.materialHashes.hashes = new Array(this.numMaterials + 1);
		this.materialHashes.byteStride = byteStride;
		this.materialHashes.version = version;
		this._materialHashesInitializedResolve();
	}
};

OtgPackage.prototype.processMetadata = function(loadContext) {

	var svf = this;

	var metadata = svf.metadata;

	initPlacement(svf, loadContext);
	var pt = svf.placementWithOffset;

	if (metadata.cameras) {
		svf.cameras = metadata.cameras;

		for (var i=0; i<svf.cameras.length; i++) {
			var cam = svf.cameras[i];

			cam.position = new LmvVector3(cam.position.x, cam.position.y, cam.position.z);
			cam.target = new LmvVector3(cam.target.x, cam.target.y, cam.target.z);
			cam.up = new LmvVector3(cam.up.x, cam.up.y, cam.up.z);

			if (pt) {
				transformCameraData(cam, pt);
			}
		}
	}

};

OtgPackage.prototype.initializeDbIdFilter = function(objectIds) {
	if (objectIds) {
		this.dbIdFilter = {};
		for (let i=0, iEnd=objectIds.length; i<iEnd; i++) {
			this.dbIdFilter[objectIds[i]] = 1;
		}
	}
};

OtgPackage.prototype.loadData = function(loadContext) {
	this.materialsCtx = this.loadMaterialHashList(loadContext, "materials_ptrs.hl");
	this.geometryCtx = this.loadGeometryHashList(loadContext, "geometry_ptrs.hl");
	this.fragmentsCtx = this.loadFragmentList(loadContext, "fragments.fl");
};

OtgPackage.prototype.makeSharedResourcePath = function(cdnUrl, whichType, hash) {

	//TODO: Make this logic preferentially use the CDN paths settings from the
	//viewable manifest instead of the .shared_assets in the per-model settings.
	//In general those will be equal though.

	if (hash.length === 10)
		hash = unpackHexString(hash);

	if (cdnUrl) {

		let shardChars = this.manifest.shared_assets.global_sharding || 0;

		//The shard prefix is a number of character cut off from the hash
		//string and brought to the beginning of the S3 key to improve S3 read
		//preformance.
		let fname = hash;
		let shardPrefix = "";
		if (shardChars) {
			shardPrefix = "/" + hash.slice(0, shardChars);
			fname = hash.slice(shardChars);
		}

		//This prefix is an account ID hash, plus a relative path
		//that is one of /t /g or /m (texture, geometry, material)
		var prefix = this.manifest.shared_assets[whichType];

		if (prefix.indexOf(FLUENT_URN_PREFIX) === 0 || prefix.indexOf(DS_OTG_CDN_PREFIX) === 0) {
			//The CDN bucket name is the first string after the last ":", so skip that too,
			//by slicing up to the first slash
			prefix = prefix.slice(prefix.indexOf("/"));
		} else {
			//it can be a relative path in case of local testing data
			//TODO: not sure this branch will ever get hit
			var split = prefix.split("/");
			prefix = "/" + (split[split.length - 1] || split[split.length - 2])+ "/";
		}

		return cdnUrl + shardPrefix + prefix + fname;

	} else {
		//Locally stored data (testing only) defaults to sharding size of 2 chars
		let shardChars = this.manifest.shared_assets.global_sharding || 2;

		let fname = hash;
		let shardPrefix = "";
		if (shardChars) {
			shardPrefix = "/" + hash.slice(0, shardChars) + "/";
			fname = hash.slice(shardChars);
		}

		var modelBasePath = pathToURL(this.basePath);
		return modelBasePath + this.manifest.shared_assets[whichType] + shardPrefix + fname;
	}
};

OtgPackage.prototype.postLoad = function(loadContext, what) {

	if (what) {
		//console.log("what", what);
		this.initialLoadProgress++;
	}

	//If required files are loaded, continue with the next
	//step of the load sequence
	if (this.initialLoadProgress === 4) {

		//Finish processing the data streams in order of dependcy

		if (this.materialsCtx) {
			this.materialsCtx.flush();
			this.geometryCtx.flush();
			this.fragmentsCtx.flush();

			this.materialsCtx = null;
			this.geometryCtx = null;
			this.fragmentsCtx = null;
		}

		if (this.fragments.numLoaded < this.metadata.stats.num_fragments)
			logger.warn("Fragments actually loaded fewer than expected.");

		loadContext.onLoaderEvent("all_fragments");
	} else {
		//In rare cases (especially node.js use), one of the content
		//files can appear before the metadata json is loaded.
		//so we have to re-trigger processing when the metadata arrives
		//(since readOne bails if there is no metadata).
		// if loading from cache, those are never set
		if (what === "metadata" && this.materialsCtx) {
			this.materialsCtx.onData(null);
			this.geometryCtx.onData(null);
			this.fragmentsCtx.onData(null);
		}
    }
};

OtgPackage.prototype.processViewPointData = function (loadContext) {
    var svf = this;
    const viewpointData = svf.manifest.assets.viewpoints;

	if (!viewpointData) {
        loadContext.onOperationComplete();
        return;
    }

    if ("camera_definitions" in viewpointData) {
        svf.loadCameraDefinition(loadContext, viewpointData['camera_definitions']);
    }
    if ('override_sets' in viewpointData) {
        svf.loadOverrideSets(loadContext, viewpointData['override_sets']);
    }
    if ("viewpoint_tree" in viewpointData) {
        svf.loadViewpointTree(loadContext, viewpointData['viewpoint_tree']);
    }
    if ("viewpoints" in viewpointData) {
        svf.loadViewpoints(loadContext, viewpointData['viewpoints']);
    }
};

OtgPackage.prototype.postLoadViewpointData = async function (loadContext) {
    this.viewpointProgress++;

	if (this.viewpointProgress !== 4) {
		return;
	}

	this.materialsCtx && await this.materialsCtx.done;

	this.processViewpoints();
	await this.getExtraMaterialHashes();
	loadContext.onLoaderEvent("viewpointData");
};

OtgPackage.prototype.loadCameraDefinition = function (loadContext, path) {
    let svf = this;

    svf.loadAsyncResource(loadContext, path, null, function (data) {
        svf.cameraDef = new PackFileReader(data);
        svf.postLoadViewpointData(loadContext);
    });
};

OtgPackage.prototype.loadOverrideSets = function (loadContext, path) {
    let svf = this;

    svf.loadAsyncResource(loadContext, path, null, function (data) {
        svf.overrideSetsPack = new PackFileReader(data);
        if (svf.overrideSetsPack) {
            for (let i = 0, iEnd = svf.overrideSetsPack.getEntryCounts(); i < iEnd; i++) {
                const set = readOverrideSet(svf.overrideSetsPack, i);

                if (set) {
                    svf.overrideSets.push(set);
                }
            }

            svf.overrideSetsPack = null;
            svf.postLoadViewpointData(loadContext);
        }
    });
};

OtgPackage.prototype.loadViewpointTree = function (loadContext, path) {
    let svf = this;

    let processViewpointTree = function () {
        const vtr = svf.viewpointTreeRoot;

        if (vtr) {
            vtr.name = vtr.name || 'Saved Viewpoints';
        }

        if (!vtr?.children) {
            return;
        }

        let order = 0;
        function traverseBubble(node) {
            if (node.children) {
                for (let i = 0; i < node.children.length; i++) {
                    traverseBubble(node.children[i]);
                }
                node.type = 'folder';
                node.role = 'viewable';
                node.guid = order++ + '';
            } else {
                if (!isNaN(node.entry)) {
                    node.order = order;

                    node.type = 'view';
                    node.role = '3d';
                    node.guid = order++ + '';
                    node.isViewpoint = true;
                }
            }
        }


        for (let i = 0; i < vtr.children.length; i++) {
            traverseBubble(vtr.children[i]);
        }
    };

    svf.loadAsyncResource(loadContext, path, null, function (data) {
        svf.viewpointTreePack = new PackFileReader(data);
        if (svf.viewpointTreePack) {
            const root = readNamedItemTree(svf.viewpointTreePack);
            svf.viewpointTreeRoot = root;
            processViewpointTree();

            svf.viewpointTreePack = null;
            svf.postLoadViewpointData(loadContext);
        }
    });
};

OtgPackage.prototype.loadViewpoints = function (loadContext, path) {
    let svf = this;

    svf.loadAsyncResource(loadContext, path, null, function (data) {
        svf.viewpointsDefPack = new PackFileReader(data);
        svf.postLoadViewpointData(loadContext);
        // Delay processing the data to make sure Camera Def is ready.
    });
};

/**
 * This function is added to make sure both viewpointsDefPack and cameraDef
 * are available.
 */
OtgPackage.prototype.processViewpoints = function () {
	if (this.viewpointsDefPack && this.cameraDef) {
        for (let i = 0, iEnd = this.viewpointsDefPack.getEntryCounts(); i < iEnd; i++) {
            const def = readViewpointDefinition(this.viewpointsDefPack, this.cameraDef, i);

            if (def) {
                this.viewpoints.push(def);
            }
        }

        this.viewpointsDefPack = null;
        this.cameraDef = null;
    }
};

OtgPackage.prototype.abort = function() {
	this.abortController.abort();
};
