import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import objectUniforms from "./object_uniforms.wgsl";

import { LmvMatrix4 } from '../../scene/LmvMatrix4';
import { BumpAllocator } from "../BumpAllocator";
import { getMaterialTextureMask } from "./MaterialUniforms";

const MAX_BATCH = 512;
const OBJECT_STRIDE = 96;
const OBJECT_STRIDE_32 = OBJECT_STRIDE / 4;
const MATERIAL_STRIDE = 32;
const COMMON_MATERIAL_UNIFORMS_SIZE = 16;
const MASS_UPDATE_THRESHOLD = 1000; // TODO: This is chosen arbitrarily, but seems to give good enough results for now

export function getObjectUniformsDeclaration(bindGroup) {
	return $wgsl(objectUniforms, { bindGroup });
}

export function colorToInt(color, opacity, gammaInput) {
	let r = color.r;
	let g = color.g;
	let b = color.b;

	//done in the shader
	// if (false && gammaInput) {
	// 	r *= r;
	// 	g *= g;
	// 	b *= b;
	// }

	return (r * 255) | ((g * 255) << 8) | ((b * 255) << 16) | ((opacity * 255) << 24);
}

export function vectorToABGR(v) {
	return (v.x * 255) | ((v.y * 255) << 8) | ((v.z * 255) << 16) | ((v.w * 255) << 24);
}

function getBufferIndex(bufferLimits, index) {
	for (let i = 0; i < bufferLimits.length; ++i) {
		if (index < bufferLimits[i]) {
			return i;
		}
	}
}

const tmpMtx = new LmvMatrix4(false);

/**
 * A helper class used to manage object uniform updates per model.
 * An instance is created per model and registers callbacks on different classes like the model, render batches or
 * fragment list in order to listen to data changes. It basically serves as an abstraction / glue layer between the
 * data model (which shouldn't know anything about the renderer) and the renderer / object uniforms (which shouldn't
 * know too many details about the data model classes).
 * The idea is to:
 * a) decouple data model and renderer classes, while still enable granular, event-based uniform updates
 * b) cache per-model data structures for faster access
 */
class UniformUpdater {
	#renderer;
	#device;
	#objectUniforms; // A reference to the object uniforms class instance that created this helper.
	#fragsPerBuffer;
	#cpuBuffer;  // References to the cpu-side uniform buffer of the object uniforms instance
	#cpuBufferInt;

	// Model specific data structures
	#model;
	#modelId;
	#iterator;
	#modelBuffers;
	#bufferLimits = [];
	#fragmentList;
	#fragOrder;
	#fragOrderLookup;

	// Bound callback functions
	#boundIteratorChangedCallback = this.#iteratorChangedCallback.bind(this);
	#boundFragOrderChangedCallback = this.#fragOrderChangedCallback.bind(this);
	#boundMeshSetCallback = this.#setOneFragment.bind(this);
	#boundTransformChangedCallback = this.#setTransform.bind(this);
	#boundMaterialChangedCallback = this.#setMaterial.bind(this);
	#boundObjectFlagsChangedCallback = this.#markScenesDirty.bind(this);
	#boundThemingColorChangedCallback = this.#setThemingColor.bind(this);

	#currentCpuBufferOffset = 0;

	// Indicates that many fragments are updated simultaneously. We stop updating individual uniforms in this case.
	#massUpdate = false;
	#updateCount = 0;

	#setBindGroup;

	constructor(renderer, objectUniforms, fragsPerBuffer, cpuBuffer, cpuBufferInt, model, modelBuffers, setBindGroup) {
		this.#renderer = renderer;
		this.#device = this.#renderer.getDevice();
		this.#objectUniforms = objectUniforms;
		this.#fragsPerBuffer = fragsPerBuffer;
		this.#cpuBuffer = cpuBuffer;
		this.#cpuBufferInt = cpuBufferInt;

		this.#model = model;
		this.#modelId = this.#model.id;
		this.#fragmentList = this.#model.getFragmentList();
		this.#modelBuffers = modelBuffers;

		this.#setBindGroup = setBindGroup;

		// Listen to fragment data changes
		this.#fragmentList.registerMeshSetCallback(this.#boundMeshSetCallback);
		this.#fragmentList.registerTransformChangedCallback(this.#boundTransformChangedCallback);
		this.#fragmentList.registerMaterialChangedCallback(this.#boundMaterialChangedCallback);
		this.#fragmentList.registerObjectFlagsChangedCallback(this.#boundObjectFlagsChangedCallback);
		this.#fragmentList.registerThemingColorChangedCallback(this.#boundThemingColorChangedCallback);

		// Listen to iterator changes
		this.#model.registerIteratorChangedCallback(this.#boundIteratorChangedCallback);
		// The model is already initialized at this point, so the initial iterator is set and we need to query it.
		this.#iteratorChangedCallback(this.#model.getIterator());
	}

	dtor() {
		// Remove callbacks
		this.#model.removeIteratorChangedCallback(this.#boundIteratorChangedCallback);
		this.#clearFragmentListCallbacks();
		this.#clearRenderbatchCallbacks();
	}

	#clearFragmentListCallbacks() {
		this.#fragmentList.removeMeshSetCallback(this.#boundMeshSetCallback);
		this.#fragmentList.removeTransformChangedCallback(this.#boundTransformChangedCallback);
		this.#fragmentList.removeMaterialChangedCallback(this.#boundMaterialChangedCallback);
		this.#fragmentList.removeObjectFlagsChangedCallback(this.#boundObjectFlagsChangedCallback);
		this.#fragmentList.removeThemingColorChangedCallback(this.#boundThemingColorChangedCallback);
	}

	#clearRenderbatchCallbacks() {
		const scenes = this.#iterator.getGeomScenes();

		for (let i = 0; i < scenes.length; ++i) {
			const scene = scenes[i];
			if (!scene || !scene.count) {
				continue;
			}

			scene.removeFragOrderChangedCallback(this.#boundFragOrderChangedCallback);
		}
	}

	/**
	 * A callback that is invoked when the model iterator changes.
	 * @param {*} iterator The new iterator.
	 */
	#iteratorChangedCallback(iterator) {
		if (this.#iterator) {
			// Deregister callbacks on render batches
			this.#clearRenderbatchCallbacks();
		}

		this.#iterator = iterator;

		// Get frag order (fragId -> render order) and compute the inverse mapping
		// Note: This assumes that the scene size is known, which is not the case for 2d models.
		// But it works for Tandem.
		this.#fragOrder = this.#iterator.getFragOrder();
		this.#fragOrderLookup = new Uint32Array(this.#fragOrder.length);
		for (let i = 0; i < this.#fragOrder.length; ++i) {
			this.#fragOrderLookup[this.#fragOrder[i]] = i;
		}

		// Get scenes and set up frag order change callbacks
		const scenes = this.#iterator.getGeomScenes();
		const scenesTemp = [];
		for (let i = 0; i < scenes.length; ++i) {
			const scene = scenes[i];
			if (!scene || !scene.count) {
				continue;
			}

			scenesTemp.push(scene);

			scene.registerFragOrderChangedCallback(this.#boundFragOrderChangedCallback);
		}

		// Sort scenes by start index and calculate scene sizes
		const sceneSizes = [];
		scenesTemp.sort((a, b) => a.start - b.start);
		for (let i = 0; i < scenesTemp.length; ++i) {
			sceneSizes.push(scenesTemp[i].count);
		}
		this.#setSceneSizes(sceneSizes);

		// Flag all scenes for batch updates
		this.#markScenesDirty();
	}

	/**
	 * A callback that handles updates of the fragment order (fragId -> render order mapping).
	 *
	 * If start and count are provided, they are expected to be within a single Renderbatch.
	 * Omit them to update all fragments.
	 * @param {Number} [start] The start of the range in the fragOrder array that has been updated.
	 * @param {Number} [count] The number of elements that have been reordered.
	 */
	#fragOrderChangedCallback(start, count) {
		// fragOrder has been updated externally (e.g. by sorting fragments in Renderbatches).
		// We need to update the inverse lookup table and reupload affected fragments.
		let fragId;
		let updateAllFragments = false;
		if (start === undefined) {
			start = 0;
			count = this.#fragmentList.fragments.length;
			updateAllFragments = true;
		}

		for (let i = start; i < start + count; ++i) {
			fragId = this.#fragOrder[i];

			// Update the frag order lookup array
			this.#fragOrderLookup[fragId] = i;
		}

		// Reupload object uniforms for the affected fragments
		if (updateAllFragments) {
			// Simply flag all batches as dirty, so they will be updated in the render loop
			this.#markScenesDirty();
		} else {
			this.updateBatch(start, count);
		}
	}

	/**
	 * Flags all scenes as dirty, so that they can be batch-updated when they are rendered the next time.
	 */
	#markScenesDirty() {
		const scenes = this.#iterator.getGeomScenes();
		let scene;
		for (let i = 0; i < scenes.length; ++i) {
			scene = scenes[i];
			if (scene) {
				scene.uniformsNeedUpdate = true;
			}
		}
	}

	/**
	 * Takes an array of scene sizes to calculate scene -> buffer assignments.
	 * @param {Array<number>} sceneSizes An array of scene sizes. They are expected to be sorted in ascending order
	 *  with respect to the corresponding scene's frag order start index.
	 */
	#setSceneSizes(sceneSizes) {
		// bufferLimits[i] stores 'last frag order index of modelBuffers[i]' + 1.
		this.#bufferLimits.length = 0;
		this.#objectUniforms.setBufferLimits(this.#modelId, this.#bufferLimits);

		let numBuffers = this.#modelBuffers.length;
		let curBufferId = 0;
		let curBuffer = this.#modelBuffers[0];
		let curBufferSize = curBuffer.size / OBJECT_STRIDE;
		let prevBufferLimit = 0;
		let curSizeSum = 0;
		let prevSizeSum = 0;
		for (let size of sceneSizes) {
			curSizeSum += size;

			// The current batch doesn't fit into the current buffer anymore
			if (curSizeSum > curBufferSize) {
				if (numBuffers <= curBufferId + 1) {
					// No more buffers available. This can happen in rare cases, if Renderbatches exceed the expected
					// MAX_BATCH number of fragments. We could check if the current last buffer can be re-allocated to
					// hold more batches, but this can cause trouble if the buffer is currently used in a draw call.
					// So we simply allocate a new buffer.
					const remainingFragments = this.#fragmentList.fragments.length - prevBufferLimit;
					const newSize = remainingFragments <= this.#fragsPerBuffer ? remainingFragments
										: this.#fragsPerBuffer;
					const newBuffer = this.#device.createBuffer({
						size: newSize * OBJECT_STRIDE,
						usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
					});
					this.#modelBuffers.push(newBuffer);
					numBuffers++;

					// Create a new bind group for the buffer
					this.#setBindGroup(this.#modelId, curBufferId + 1, newBuffer);
				}

				// Finalize the current buffer limit and continue with the next buffer
				this.#bufferLimits[curBufferId++] = prevBufferLimit + prevSizeSum;
				prevBufferLimit += prevSizeSum;
				curSizeSum = size;
				curBufferSize = this.#modelBuffers[curBufferId].size / OBJECT_STRIDE;
			}

			prevSizeSum = curSizeSum;
		}

		this.#bufferLimits[curBufferId] = prevBufferLimit + prevSizeSum;
	}

	/**
	 * Writes the uniform data of a single fragment into the CPU buffer, at currentCpuBufferOffset.
	 * @param {number} fragId The fragment id.
	 * @param {THREE.Material} [material] An optional material. If provided, the fragment's material reference uniform
	 *  will be set.
	 * @returns {number} The number of bytes to write to the GPU for the fragment.
	 */
	#setOneFragmentCPU(fragId, material) {
		if (this.#fragmentList.hasGlobalTransform || (this.#fragmentList.vizflags[fragId] & 0x10)) {
			this.#fragmentList.getWorldMatrix(fragId, tmpMtx);
			this.#cpuBuffer.set(tmpMtx.elements, this.#currentCpuBufferOffset);
		} else {
			const i = (fragId * 12);

			const transforms = this.#fragmentList.transforms;
			// We only store the upper 3 rows explicitly.
			// The last row is always (0,0,0,1).
			this.#cpuBuffer[this.#currentCpuBufferOffset] = transforms[i];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 1] = transforms[i + 1];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 2] = transforms[i + 2];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 3] = 0;
			this.#cpuBuffer[this.#currentCpuBufferOffset + 4] = transforms[i + 3];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 5] = transforms[i + 4];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 6] = transforms[i + 5];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 7] = 0;
			this.#cpuBuffer[this.#currentCpuBufferOffset + 8] = transforms[i + 6];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 9] = transforms[i + 7];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 10] = transforms[i + 8];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 11] = 0;
			this.#cpuBuffer[this.#currentCpuBufferOffset + 12] = transforms[i + 9];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 13] = transforms[i + 10];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 14] = transforms[i + 11];
			this.#cpuBuffer[this.#currentCpuBufferOffset + 15] = 1;
		}

		const dbId = this.#fragmentList.fragments.fragId2dbId[fragId];
		this.#cpuBufferInt[this.#currentCpuBufferOffset + 16] = dbId;
		this.#cpuBufferInt[this.#currentCpuBufferOffset + 17] = this.#modelId;
		this.#cpuBufferInt[this.#currentCpuBufferOffset + 18] = this.#fragmentList.objectFlagsCB && this.#fragmentList.objectFlagsCB(dbId);

		const themingColor = this.#fragmentList.db2ThemingColor[dbId];
		if (themingColor && themingColor.w > 0.0) {
			this.#cpuBufferInt[this.#currentCpuBufferOffset + 19] = vectorToABGR(themingColor);
		} else {
			this.#cpuBufferInt[this.#currentCpuBufferOffset + 19] = 0;
		}

		let length = 80;

		if (material) {
			this.#objectUniforms.setMaterialReference(this.#currentCpuBufferOffset, material);
			length = OBJECT_STRIDE;
		}

		return length;
	}

	/**
	 * Sets and uploads the uniforms of a single fragment.
	 * @param {number} fragId The fragment id.
	 * @param {THREE.Material} [material] An optional material. If provided, the fragment's material reference uniform
	 *  will be set.
	 */
	#setOneFragment(fragId, material) {
		if (this.#updateHeuristic()) {
			return;
		}

		const length = this.#setOneFragmentCPU(fragId, material);
		const index = this.#fragOrderLookup[fragId];
		this.#uploadFragments(index, 0, length);
	}

	/**
	 * Expose access to #fragOrderLookup - which provides for each fragId the index
	 * in the batched object uniform buffer where the uniforms for this fragId are stored.
	 *
	 * @param {number} fragId
	 * @returns {number} The index needed to find the uniforms for this fragId in the batched object uniform buffer.
	 */
	getObjectIndex(fragId) {
		return this.#fragOrderLookup[fragId];
	}

	/**
	 * Sets and uploads the material reference uniform for the object at the specified index.
	 * @param {number} fragId The fragment to update.
	 * @param {THREE.Material} material The material to associate with the fragment.
	 */
	#setMaterial(fragId, material, fromLoader) {
		if (this.#updateHeuristic()) {
			return;
		}

		// Set material index in object uniforms buffer
		this.#objectUniforms.setMaterialReference(this.#currentCpuBufferOffset, material);
		const index = this.#fragOrderLookup[fragId];
		this.#uploadFragments(index, 80, 4);

		// No need to invalidate bundles during the load process. Batches won't be complete yet anyway, so we're not
		// using bundles, too.
		if (!fromLoader) {
			// We invalidate aggressively here. In many cases, invalidation might not actually be required. But if the
			// material is new, or if the fragments previously required a different shader (e.g. due to a different
			// texture configuration), we have to invalidate. It's just simpler to invalidate in all cases.
			this.#renderer.invalidateRenderBundles(this.#model);
		}
	}

	/**
	 * Sets and uploads the transform uniform for the object at the specified index.
	 * @param {number} fragId The fragment to update.
	 * @param {THREE.Matrix4} matrix The transform of the fragment.
	 */
	#setTransform(fragId) {
		if (this.#updateHeuristic()) {
			return;
		}

		this.#fragmentList.getWorldMatrix(fragId, tmpMtx);

		this.#cpuBuffer.set(tmpMtx.elements, this.#currentCpuBufferOffset);
		const index = this.#fragOrderLookup[fragId];
		this.#uploadFragments(index, 0, 64);
	}

	/**
	 * Sets and uploads the theming color uniform for the object at the specified index.
	 * @param {number} fragId The fragment to update.
	 * @param {THREE.Vector4} color The theming color vector of the fragment.
	 */
	#setThemingColor(fragId, color) {
		if (this.#updateHeuristic()) {
			return;
		}

		if (color.w > 0.0) {
			this.#cpuBufferInt[this.#currentCpuBufferOffset + 19] = vectorToABGR(color);
		} else {
			this.#cpuBufferInt[this.#currentCpuBufferOffset + 19] = 0;
		}

		const index = this.#fragOrderLookup[fragId];
		this.#uploadFragments(index, 76, 4);
	}

	// This does not check for buffer boundaries! It's intended to update Renderbatches, which will never cross buffer
	// boundaries. Note that this function only works for 3D models.
	updateBatch(startIndex, count) {
		this.#currentCpuBufferOffset = 0;
		let fragId;
		let material;
		let storedCount = 0;
		let uploadOffset = startIndex;
		for (let i = startIndex; i < startIndex + count; ++i) {
			fragId = this.#fragOrder[i];
			material = this.#fragmentList.getMaterial(fragId);
			this.#setOneFragmentCPU(fragId, material);
			this.#currentCpuBufferOffset += OBJECT_STRIDE_32;

			// It's possible that Renderbatches actually contain more than 512 fragments.
			// In this case, we need to upload after every 512 fragments, because the CPU buffer can't handle more.
			if (++storedCount === MAX_BATCH) {
				this.#uploadFragments(uploadOffset, 0, storedCount * OBJECT_STRIDE);
				uploadOffset += storedCount;
				storedCount = 0;
				this.#currentCpuBufferOffset = 0;
			}
		}

		if (storedCount > 0) {
			this.#uploadFragments(uploadOffset, 0, storedCount * OBJECT_STRIDE);
		}

		this.#currentCpuBufferOffset = 0;
	}

	#updateHeuristic() {
		if (this.#massUpdate) {
			return true;
		}

		// If the number of uniform updates exceeds a threshold, we stop updating individual fragments
		// (in some cases, e.g. when updating animation transforms or theming colors).
		// Scenes are marked as dirty and uniforms will be updated in batches in the render loop.
		if (++this.#updateCount > MASS_UPDATE_THRESHOLD) {
			this.#massUpdate = true;
			this.#markScenesDirty();
			return true;
		}

		return false;
	}

	resetUpdateHeuristic() {
		// Called in the render loop. We don't consider fragment updates 'mass' updates if we render before
		// hitting the mass update threshold.
		// TODO: For animations, this means that we will update the first MASS_UPDATE_THRESHOLD fragments individually
		// in the next iteration, before entering mass update mode again. Probably not a big deal, but we might want to
		// optimize this later.
		this.#updateCount = 0;
		this.#massUpdate = false;
	}

	/**
	 * Uploads fragment data to the GPU.
	 * Note that this method does not handle buffer boundaries. Uploading data of multiple fragments only works if
	 * they're all stored adjacently in the same GPU buffer.
	 * @param {number} index The index of the first fragment to upload in the fragment order array.
	 * @param {number} start The offset in the CPU buffer, in bytes.
	 * @param {number} size The number of bytes to upload from the CPU buffer.
	 */
	#uploadFragments(index, start = 0, size = OBJECT_STRIDE) {
		const bufferIndex = getBufferIndex(this.#bufferLimits, index);
		const buffer = this.#modelBuffers[bufferIndex];
		const offset = bufferIndex === 0 ? index : index - this.#bufferLimits[bufferIndex - 1];

		this.#device.queue.writeBuffer(buffer, offset * OBJECT_STRIDE + start, this.#cpuBuffer.buffer, start, size);
	}
}

/** How this works:
 * We have a small shared object uniform buffer, enough for MAX_BATCH objects.
 * This buffer is used when rendering anything but actual models, e.g. scene, sceneAfter, overlays, ...
 * If this buffer is used, uniforms need to be set for every rendered object, via the setOne... methods.
 * It's a shared buffer that is constantly rewritten.
 * There's a CPU-side buffer of the same size that is used to aggregate object data and push it into the GPU buffer.
 *
 * We also have larger object uniform buffers per model. They are allocated according to the model's size
 * (# of fragments) and supposed to persist all object uniforms of a model.
 * The idea is to avoid uploading all object uniforms of a fragment every time it is rendered, and only update the
 * uniform buffers granularly if fragment data actually changes.
 * The CPU-side buffer is reused to push individual fragment's data into the large, model-specific GPU buffers.
 * Object uniforms are usually not updated in the render loop. They are updated immediately when data changes in the
 * fragment list, via a UniformUpdater instance (see above). Only if too many fragments are updated in one pass, we
 * fall back to batched uniform updates at the beginning of the render loop.
 *
 * A render pass needs to determine if it's currently rendering a model scene (i.e. RenderBatch)
 * or one of the other scenes.
 * 1) Rendering a non-model scene:
 *   - The bind group needs to be obtained via getBindGroup() (optionally provide -1 as the only parameter)
 *   - Object and material uniforms need to be set via the setOne... methods for every object
 *   - writeToQueue needs to be called prior to submitting the render command buffer
 *   - Each render command buffer may only contain draw calls for up to MAX_BATCH objects
 * 2) Rendering a model scene:
 *   - The bind group needs to be obtained via getBindGroup(modelId, sceneStart)
 *   - Renderbatches may be flagged as dirty, requiring batched uniform updates;
 *      updateBatch needs to be called in this case
 *   - Materials need to be set up (once) via initMaterialUpdateHook; object uniforms do not need to be set
 *   - A material's needsUpdate flag needs to be reset after the pipeline's draw function has been called
 *   - writeToQueue does not need to be called
 *   - Render command buffers may contain draw calls for an unlimited number of objects
 */
export class ObjectUniforms extends BumpAllocator {

	#renderer;
	#device;

	#objectUniforms;
	#objectUniformsCPU = new Float32Array(OBJECT_STRIDE_32 * MAX_BATCH);
	#objectUniformsCPUInt = new Int32Array(this.#objectUniformsCPU.buffer);
	#objectUniformsCPUUint8 = new Uint8Array(this.#objectUniformsCPU.buffer); // Used for type-agnostic copying of interleaved instance data

	#modelBuffers = new Map(); // model id -> array of GPU buffers
	#modelBufferSize; // The maximum size per buffer
	#fragsPerBuffer;  // The number of objects / fragments per buffer
	// model id -> array of offsets, where value i is the last valid item in buffer i; empty array if only 1 buffer
	#bufferLimits = new Map();
	#modelBindGroups = new Map(); // model id -> array of bind groups, one for each buffer
	#modelUniformUpdaters = new Map(); // model id -> uniform updater instance
	#currentModelId; #currentBufferIndex; #currentBufferOffset;
	#currentBindGroup;

	#materialUniforms;
	#materialUniformsCPU = new Float32Array(MATERIAL_STRIDE / 4);
	#materialUniformsCPUInt = new Int32Array(this.#materialUniformsCPU.buffer);
	#boundMaterialUpdateCallback; #boundRemoveMaterialUpdateCallbacks;

	#commonMaterialUniforms;
	#commonMaterialUniformsCPU = new Float32Array(COMMON_MATERIAL_UNIFORMS_SIZE / 4);
	#commonMaterialUniformsCPUInt = new Int32Array(this.#commonMaterialUniformsCPU.buffer);

	#objectUniformsLayout;
	#objectUniformsBindGroup;

	constructor(renderer) {
		super(renderer.getDevice());
		this.#renderer = renderer;
		this.#device = this.#renderer.getDevice();

		this.MAX_BATCH = MAX_BATCH;
		this.OBJECT_STRIDE_32 = OBJECT_STRIDE_32;

		// Determine max buffer size to hold a natural number of objects
		const bufferLimit = this.#device.limits.maxStorageBufferBindingSize;
		this.#modelBufferSize = Math.floor(bufferLimit / OBJECT_STRIDE) * OBJECT_STRIDE;
		this.#fragsPerBuffer = this.#modelBufferSize / OBJECT_STRIDE;

		this.#setupSharedObjectUniforms();

		this.#boundMaterialUpdateCallback = this.#materialUpdateCallback.bind(this);
		this.#boundRemoveMaterialUpdateCallbacks = this.#removeMaterialUpdateCallbacks.bind(this);
	}

	#createBindGroup(objectUniformsBuffer) {
		return this.#device.createBindGroup({
			layout: this.#objectUniformsLayout,
			entries: [
				{
					binding: 0,
					resource: {
						buffer: objectUniformsBuffer
					},
				},
				{
					binding: 1,
					resource: {
						buffer: this.#materialUniforms
					},
				},
				{
					binding: 2,
					resource: {
						buffer: this.#commonMaterialUniforms
					},
				}
			],
		});
	}

	#setBindGroup(modelId, bufferIndex, buffer) {
		const bindGroups = this.#modelBindGroups.get(modelId);
		if (bindGroups) {
			bindGroups[bufferIndex] = this.#createBindGroup(buffer);
		}
	}

	#setupSharedObjectUniforms() {

		this.#objectUniforms = this.#device.createBuffer({
			size: OBJECT_STRIDE * MAX_BATCH,
			usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
			//usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
		});

		this.#commonMaterialUniforms = this.#device.createBuffer({
			size: COMMON_MATERIAL_UNIFORMS_SIZE,
			usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
		});

		// NOTE: We keep the implementation simple by pre-allocating a material uniform buffer that is large enough to
		// hold > 1 million materials (see size constant in bump allocator) and assuming that this will be sufficient.
		// The more granular (and scalable) approach would be to allocate more, but smaller buffers on demand.
		// But this would require multiple bind groups. We would need to add code to switch the bind group inside the
		// render loop, which would require changes in different places and affect the overall flow / execution order.
		// Furthermore, we would need to keep track of the actual underlying buffer per material.
		const materialBufferAllocation = this.mAlloc(0); // Allocate a zero-size material to create an empty buffer
		materialBufferAllocation[0].stride = MATERIAL_STRIDE;
		this.#materialUniforms = materialBufferAllocation[0].buffer;

		this.#objectUniformsLayout = this.#device.createBindGroupLayout({
			entries: [
				{
					binding: 0,
					visibility: GPUShaderStage.VERTEX | GPUShaderStage.FRAGMENT,
					buffer: {
						type: "read-only-storage"
					}
				},
				{
					binding: 1,
					visibility: GPUShaderStage.FRAGMENT,
					buffer: {
						type: "read-only-storage"
					}
				},
				{
					binding: 2,
					visibility: GPUShaderStage.FRAGMENT,
					buffer: {
						type: "uniform"
					}
				},
			]
		});

		this.#objectUniformsBindGroup = this.#createBindGroup(this.#objectUniforms);
	}

	#initMaterialBuffer(material) {
		let materialBufferOffset;
		if (!material.__gpumb) {
			const bufferAllocation = this.mAlloc(MATERIAL_STRIDE);
			material.__gpumb = bufferAllocation[0];
			material.__gpumbOffset = materialBufferOffset = bufferAllocation[1];
			const disposeHandler = () => {
				this.mFree(material);
				material.removeEventListener('dispose', disposeHandler);
			};
			material.addEventListener('dispose', disposeHandler);
		} else {
			materialBufferOffset = material.__gpumbOffset;
		}

		return materialBufferOffset;
	}

	#uploadMaterialBuffer(offset) {
		this.#device.queue.writeBuffer(this.#materialUniforms, offset, this.#materialUniformsCPU.buffer, 0, this.#materialUniformsCPU.byteLength);
	}

	/**
	 * Initialize object uniforms for a new model.
	 * @param {RenderModel} model The model to initialize the uniforms for.
	 */
	addModel(model) {
		if (this.#modelBuffers.has(model.id)) {
			return;
		}

		const modelSize = model.getFragmentList().fragments.length * OBJECT_STRIDE;
		if (modelSize === 0) {
			return;
		}

		// Create uniform buffers and bind groups
		let numBuffers = Math.ceil(modelSize / this.#modelBufferSize);
		// Buffers will contain data sorted by Renderbatches, but a Renderbatch's range may not cross buffer boundaries.
		// So we need to make sure that we allocate enough memory to hold all data, even if wasting some bytes.
		// Note that this relies on MAX_BATCH as the upper limit for fragments per batch. In rare cases, batches can be
		// much larger. Additional buffers are allocated in the uniform updater class in this case.
		const potentialWaste = (numBuffers - 1) * (MAX_BATCH - 1) * OBJECT_STRIDE;
		if (numBuffers * this.#modelBufferSize < (modelSize + potentialWaste)) {
			numBuffers += 1;
		}
		let lastBufferSize = (((modelSize + potentialWaste) / this.#modelBufferSize) % 1) * this.#modelBufferSize;
		if (lastBufferSize === 0) {
			lastBufferSize = this.#modelBufferSize;
		} else {
			//round up to next multiple of 4
			lastBufferSize = (lastBufferSize + 3) & 0xfffffffc;
		}
		const modelBuffers = [];
		const modelBindGroups = [];

		for (let i = 0; i < numBuffers; ++i) {
			const size = (i === numBuffers - 1) ? lastBufferSize : this.#modelBufferSize;
			const buffer = this.#device.createBuffer({
				size,
				usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
			});

			modelBuffers.push(buffer);

			const bindGroup = this.#createBindGroup(buffer);
			modelBindGroups.push(bindGroup);
		}

		this.#modelBuffers.set(model.id, modelBuffers);
		this.#modelBindGroups.set(model.id, modelBindGroups);

		// Create a uniform updater instance for the model. It listens to model-specific change events and encapsulates
		// granular uniform updates for that model.
		const uniformUpdater = new UniformUpdater(this.#renderer, this, this.#fragsPerBuffer, this.#objectUniformsCPU,
			this.#objectUniformsCPUInt, model, modelBuffers, this.#setBindGroup.bind(this));
		this.#modelUniformUpdaters.set(model.id, uniformUpdater);
	}

	/**
	 * Clean up object uniform data for a model.
	 * @param {RenderModel} model The model to clean up resources for.
	 */
	removeModel(model) {
		if (this.#modelBuffers.has(model.id)) {
			const modelBuffers = this.#modelBuffers.get(model.id);
			for (const buffer of modelBuffers) {
				buffer.destroy();
			}

			this.#modelBuffers.delete(model.id);
			this.#bufferLimits.delete(model.id);

			const uniformUpdater = this.#modelUniformUpdaters.get(model.id);
			uniformUpdater.dtor();
			this.#modelUniformUpdaters.delete(model.id);

			this.#currentModelId = null;
			this.#currentBufferIndex = null;
			this.#currentBufferOffset = null;
			this.#currentBindGroup = null;
		}
	}

	/**
	 * A callback that is invoked as a material's update handler.
	 * @param {*} event The update event. The material can be accessed via event.target.
	 */
	#materialUpdateCallback(event) {
		// TODO: How to handle the case where both needsUpdate and uniformsNeedUpdate are set?
		// This handler will be invoked twice then...
		const material = event.target;
		const materialTextureMask = getMaterialTextureMask(material);
		this.setOneMaterialData(material, materialTextureMask, true);
	}

	#removeMaterialUpdateCallbacks(event) {
		const material = event.target;
		material.removeEventListener('update', this.#boundMaterialUpdateCallback);
		material.removeEventListener('dispose', this.#boundRemoveMaterialUpdateCallbacks);
	}

	/**
	 * Initializes a material update hook on the material, to update material uniforms whenever the material changes.
	 * The function will also upload the material uniforms when it encounters a material for the first time.
	 * If the update hook has already been configured, this function is a no-op.
	 *
	 * Note: Use this only for materials that are referenced in the main scene, e.g. in RenderBatches.
	 * Three.js scenes (scene, sceneAfter, overlays, 2d scenes, ...) have to update materials in the render loop via
	 * setOneMaterialData(2D).
	 * @param {THREE.Material} material The material that should be watched for updates.
	 * @param {Number} materialTextureMask The material's texture mask, as returned by initMaterialBindings.
	 */
	initMaterialUpdateHook(material, materialTextureMask) {
		if (!material.hasEventListener('update', this.#boundMaterialUpdateCallback)) {
			material.addEventListener('update', this.#boundMaterialUpdateCallback);
			material.addEventListener('dispose', this.#boundRemoveMaterialUpdateCallbacks);

			// We need to initialize the material for our Renderer even if the material is not dirty
			// (another renderer we share resources with might have updated it already).
			const updateThroughCallback = true; // forces upload
			this.setOneMaterialData(material, materialTextureMask, updateThroughCallback);
		}
	}

	setOne(mesh, itemOffset, material, materialTextureMask) {
		this.setOneObjectData(mesh, itemOffset);
		this.setOneMaterialData(material, materialTextureMask);
	}

	/**
	 * Write the material reference into the CPU buffer.
	 * @param {number} offset The object offset in the CPU buffer, in floats.
	 * @param {THREE.Material} material The material to reference.
	 */
	setMaterialReference(offset, material) {
		// Set material index in object uniforms buffer
		const materialBufferOffset = this.#initMaterialBuffer(material);
		this.#objectUniformsCPUInt[offset + 20] = materialBufferOffset / MATERIAL_STRIDE;
	}

	setOneMaterialData(material, materialTextureMask, updateThroughCallback = false) {
		if (updateThroughCallback || material.needsUpdate || material.uniformsNeedUpdate) {
			this.#initMaterialBuffer(material);

			const isGhosted = (material.opacity < 0) ? 1 : 0;
			const doNotCut = material.doNotCut ? 2 : 0;
			const alphaTest = material.alphaTest > 0 ? 4 : 0;
			const hatchPattern = material.hatchPattern ? 8 : 0;
			this.#materialUniformsCPUInt[0] = isGhosted | doNotCut | alphaTest | hatchPattern |
				(materialTextureMask << 16);

			if (material.uniformsNeedUpdate) {

				//TODO: gammaInput is hardcoded to true here, it's normally a property of the renderer
				//and we need it off when tone mapping is not used.
				material.__gpuDiffuse = colorToInt(material.color, Math.abs(material.opacity), true);

				if (material.specular) {
					material.__gpuSpecular = colorToInt(material.specular, material.reflectivity, true);
				} else {
					material.__gpuSpecular = 0;
				}

				material.uniformsNeedUpdate = false;
			}

			this.#materialUniformsCPUInt[1] = material.__gpuDiffuse;
			this.#materialUniformsCPUInt[2] = material.__gpuSpecular;

			this.#materialUniformsCPU[3] = material.shininess;

			if (material.hatchPattern) {
				this.#materialUniformsCPU[4] = material.hatchParams.x;
				this.#materialUniformsCPU[5] = material.hatchParams.y;
				this.#materialUniformsCPU[6] = material.hatchTintIntensity;
			}

			this.#materialUniformsCPUInt[7] = ((material.heatmapSensorOffset ?? 0) << 8) + material.heatmapSensorCount ?? 0;

			this.#uploadMaterialBuffer(material.__gpumbOffset);

			// The logic in getPipelineHash and activateMaterialBindings checks this to know when
			// to recompute various material and shader flags. These methods need to be called before this one
			// (usually in pipeline.drawOne). We reset it here because this is the last step before actually rendering
			// the material.
			// There's one exception to that rule, though. Materials can be updated through an update event, in which
			// case this method is called before the draw function (which might not even be called at all). To manage
			// the needsUpdate state properly, passes that use material update callbacks need to reset the needsUpdate
			// flag when finishing a render pass.
			if (!updateThroughCallback) {
				material.needsUpdate = false;
			}
		}
	}

	setOneMaterialData2D(material, materialTextureMask) {
		if (material.needsUpdate || material.uniformsNeedUpdate) {
			this.#initMaterialBuffer(material);

			const isGhosted = (material.opacity < 0) ? 1 : 0;
			const doNotCut = material.doNotCut ? 2 : 0;
			const alphaTest = material.alphaTest > 0 ? 4 : 0;
			const hatchPattern = material.hatchPattern ? 8 : 0;
			this.#materialUniformsCPUInt[0] = isGhosted | doNotCut | alphaTest | hatchPattern |
				(materialTextureMask << 16);

			if (material.uniformsNeedUpdate) {

				material.__gpuDiffuse = ((material.opacity || 1.0) * 255.0) << 24;

				material.uniformsNeedUpdate = false;
			}

			this.#materialUniformsCPUInt[1] = material.__gpuDiffuse;

			if (material.hatchPattern) {
				this.#materialUniformsCPU[4] = material.hatchParams.x;
				this.#materialUniformsCPU[5] = material.hatchParams.y;
				this.#materialUniformsCPU[6] = material.hatchTintIntensity;
			}

			this.#uploadMaterialBuffer(material.__gpumbOffset);

			// The logic in getPipelineHash and activateMaterialBindings checks this to know when
			// to recompute various material and shader flags. These methods need to be called before this one
			// (usually in pipeline.drawOne). We reset it here because this is the last step before actually rendering
			// the material.
			material.needsUpdate = false;
		}
	}

	setOneObjectData(mesh, itemOffset) {

		let baseOffset = itemOffset * OBJECT_STRIDE_32;

		this.#objectUniformsCPU.set(mesh.matrixWorld.elements, baseOffset);
		this.#objectUniformsCPUInt[baseOffset + 16] = mesh.dbId;
		this.#objectUniformsCPUInt[baseOffset + 17] = mesh.modelId;
		this.#objectUniformsCPUInt[baseOffset + 18] = mesh.objectFlags || 0;

		const themingColor = mesh.themingColor;
		if (themingColor && themingColor.w > 0.0) {
			this.#objectUniformsCPUInt[baseOffset + 19] = vectorToABGR(themingColor);
		} else {
			this.#objectUniformsCPUInt[baseOffset + 19] = 0;
		}

		this.setMaterialReference(baseOffset, mesh.material);
	}

	/*
	 * Set the object data for a range of objects by getting the data from an instanced mesh.
	 */
	setObjectDataFromInstanceBuffer(instancedMesh, itemOffset) {
		const geom       = instancedMesh.geometry;
		const attrib     = geom.attributes.instanceUniforms;
		const srcData    = attrib.array;
		const dstData    = this.#objectUniformsCPUUint8;
		const byteOffset = itemOffset * OBJECT_STRIDE;
		dstData.set(srcData, byteOffset);

		// fill in (identical) material reference to all instances
		for (let i=0; i<geom.numInstances; i++) {
			const offset = (itemOffset + i) * OBJECT_STRIDE_32;
			this.setMaterialReference(offset, instancedMesh.material);
		}
	}

	/**
	 * Updates the uniforms of an entire RenderBatch. Only works for actual RenderBatches
	 * (i.e. scenes of a RenderModel), not wrapped three scenes.
	 * @param {RenderBatch} renderBatch
	 */
	updateBatch(renderBatch) {
		const modelId = renderBatch.frags.modelId;
		const start = renderBatch.start;
		const count = renderBatch.lastItem - start;
		this.#modelUniformUpdaters.get(modelId)?.updateBatch(start, count);
	}

	setEdgeColorInt(edgeColorInt) {
		if (edgeColorInt !== this.#commonMaterialUniformsCPUInt[0]) {
			this.#commonMaterialUniformsCPUInt[0] = edgeColorInt;
			this.#device.queue.writeBuffer(this.#commonMaterialUniforms, 0, this.#commonMaterialUniformsCPU.buffer, 0,
				this.#commonMaterialUniformsCPU.byteLength);
		}
	}

	setDoNotCutOverride(value) {
		if (value !== !!this.#commonMaterialUniformsCPUInt[1]) {
			this.#commonMaterialUniformsCPUInt[1] = value;
			this.#device.queue.writeBuffer(this.#commonMaterialUniforms, 0, this.#commonMaterialUniformsCPU.buffer, 0,
				this.#commonMaterialUniformsCPU.byteLength);
		}
	}

	getBufferInt() {
		return this.#objectUniformsCPUInt;
	}

	getBufferFloat() {
		return this.#objectUniformsCPU;
	}

	getObjectStride() {
		return OBJECT_STRIDE;
	}

	// This must only be called when uniforms have been set via setOne or setOneObjectData!
	writeToQueue(itemsAdded) {
		let uniformBytes = itemsAdded * OBJECT_STRIDE;

		//round up to next multiple of 256
		uniformBytes = (uniformBytes + 255) & 0xffffff00;

		this.#device.queue.writeBuffer(this.#objectUniforms, 0, this.#objectUniformsCPU.buffer, 0, uniformBytes);
	}

	resetUpdateHeuristic(modelId) {
		this.#modelUniformUpdaters.get(modelId)?.resetUpdateHeuristic();
	}

	getRenderIndex(index) {
		return index - this.#currentBufferOffset;
	}

	setBufferLimits(modelId, bufferLimits) {
		this.#bufferLimits.set(modelId, bufferLimits);
	}

	/**
	 * Given a fragmentId, this method finds the index needed to find the uniforms of this fragment in the
	 * batched object uniform buffer. Note that this lookup is not needed if the objectIndex matches
	 * with the order of the fragments in the current RenderBatch.
	 * Using the function requires that ObjectUniforms has already been initialized for the currently used model iterator.
	 *
	 * @param {number} fragId
	 * @returns {number} The index needed to find the uniforms for this fragId in the batched object uniform buffer.
	 */
	getObjectIndex(fragId) {
		const updater = this.#modelUniformUpdaters.get(this.#currentModelId);
		return updater.getObjectIndex(fragId);
	}

	/**
	 * Get the bind group for the provided model and fragment order index.
	 * @param {number} [modelId=-1] The id of the model to render. -1 returns the default bind group.
	 * @param {number} [index] The fragment order index of the first fragment to render, e.g. rBatch.start.
	 * @returns {*} The bind group to use for rendering.
	 */
	getBindGroup(modelId = -1, index = 0) {
		if (modelId !== -1) {
			this.MAX_BATCH = Infinity;
			const bufferLimits = this.#bufferLimits.get(modelId);
			const bufferIndex = getBufferIndex(bufferLimits, index);

			if (modelId === this.#currentModelId && bufferIndex === this.#currentBufferIndex) {
				return this.#currentBindGroup;
			}

			this.#currentModelId = modelId;
			this.#currentBufferIndex = bufferIndex;
			this.#currentBufferOffset = bufferIndex === 0 ? 0 : bufferLimits[bufferIndex - 1];
			this.#currentBindGroup = this.#modelBindGroups.get(modelId)[bufferIndex];
			return this.#currentBindGroup;
		}

		this.MAX_BATCH = MAX_BATCH;
		return this.#objectUniformsBindGroup;
	}

	getLayout() {
		return this.#objectUniformsLayout;
	}

}
