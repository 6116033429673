import * as EventTypes from "../../../application/EventTypes";

import { BvhNode } from './BvhNode';
import { ConsolidationTask } from './tasks/ConsolidationTask';
import { RemainingFragmentsTask } from './tasks/RemainingFragmentsTask';
import { InstancedMeshUploadTask } from './tasks/InstancedMeshUploadTask';
import { NO_MESH_FOR_FRAGMENT, MESH_STILL_PENDING, MIN_VALUE_FOR_PENDING_RANGE } from '../consolidation/Consolidation';
import { ModelIteratorBVH } from '../ModelIteratorBVH';
import { GPU_MEMORY_LIMIT } from "../../globals";
import { analytics } from "../../../analytics";

/** @import { RenderModel } from "../RenderModel" */
/** @import { Viewer3DImpl } from "../../../application/Viewer3DImpl" */
/** @import { ModelIteratorBVH } from "../ModelIteratorBVH" */
/** @import { WebGLRenderer } from "../../render/WebGLRenderer" */

/**
 * @typedef OutOfCoreTask
 * @property {Function} execute           - Execute the task
 * @property {Function} freeMemory        - Free the memory allocated for the task
 * @property {Number} memoryCost          - The memory cost of the task
 * @property {Function} getFreeableMemory - Get the memory that can be freed by the task
 */

/**
 * Class responsible for managing the memory for BVH tiles
 * 
 * @alias Autodesk.Viewing.Private.OutOfCoreTileManager
 * @private
 */
export class OutOfCoreTileManager {
    // Global data structures that are shared across all viewer instances and models
    static #memoryLimit = 0;
    static #memoryConsumed = 0;
    static #stats = {
      uploadedThisFrame: 0,
      removedThisFrame: 0,
      uploadedLastSecond: 0,
      removedLastSecond: 0,
      uploadedThisSecond: 0,
      removedThisSecond: 0
    };

    /** @type {BvhNode[]} */  
    static #bvhNodesPendingQueue = [];

    /** @type {BvhNode[]} */  
    static #bvhNodesWorkingSetOnGpu = [];

    static #statsUpdateTimer;

    /** @type {Set<OutOfCoreTileManager>} Out of core tile managers that have not yet received a viewer.addModel event */
    static notYetAddedManagers = new Set();

    /** @type {Array<BvhNode>} BVHNodes that are still waiting for geometries from the server */
    static pendingNodes = [];

    /** @type {Map<LeanBufferGeometry, number>} */
    static geomRefCounts = new Map();

    /** @type {Map<Model|string, OutOfCoreTileManager} */
    static modelManagerRegistry = new Map();

    /** @type {Function[]} */
    static globalTasks = [];

    static onlyGlobalTasksExecuted = false;

    // Instance data structures that are specific to a model
    #frameCounts = [];

    /** @type {RenderModel} */
    model;
    
    /** @type {Array<BvhNode>} can be sparse! */
    bvhNodes = [];

    /** @type {Map<number, Set<number>>} */
    iteratorLockedTiles = new Map();

    /** @type {Viewer3DImpl[]} */
    #viewers = [];

    /** @type {WebGLRenderer} */
    #renderer;

    /** @type {ModelIteratorBVH[]} */
    iteratorIdRegistry = [];
    
    /** @type {Number} - start time stamp when model was created */
    static #t0_ModelCreation = undefined;

    /** @type {Number} - end time stamp for GPU upload reaching the memory limit if so */
    static #t1_GPUUpload = undefined;

    /**
     * Creates a new OutOfCoreTileManager
     * @param {RenderModel} model - The model for which this tile manager is responsible
     */
    constructor(model) {
      this.model = model;
      OutOfCoreTileManager.setMemoryLimit(GPU_MEMORY_LIMIT);
    }

    /**
     * Returns the BVH node for the given node index or returns a new one if none existed
     * 
     * @param {number} nodeIdx 
     * @returns {BvhNode}
     */
    getBvhNode(nodeIdx) {
      let node = this.bvhNodes[nodeIdx];
      if (!node) {
        node = new BvhNode(nodeIdx, this.model, this);
        this.bvhNodes[nodeIdx] = node;
      }

      return node;
    }

    /**
     * Checks, whether the given BVH node has already been fully initialized
     * @param {number} bvhNodeId 
     * @returns 
     */
    bvhNodeInitialized(bvhNodeId) {
      let node = this.bvhNodes[bvhNodeId];
      return node && node.initialized;
    }

    /**
     * Adds a task for the given meshIndex to the OutOfCoreTileManager.
     * Depending on the type of the meshIndex, it will either create a ConsolidationTask or an InstancedMeshUploadTask.
     *
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {number} meshIndex - Index of consolidated/instanced mesh
     * @param {FragmentList} fragList - Fragment list for the model
     * @returns {null|undefined} Returns null if consolidation is not available, undefined otherwise.
     */
    addTask(bvhNodeId, meshIndex, fragList) {
      const consolidation = this.model.getConsolidation();

      if (!consolidation) {
          return null;
      }

      let bvhNode = this.getBvhNode(bvhNodeId);
      // Node already consolidated?
      if (!consolidation.meshGeometryAvailable(meshIndex)) {
        // Did we already create a task for this meshIndex?
        if (!bvhNode.hasTask(meshIndex)) {
          let newTask = new ConsolidationTask(
              this, 
              meshIndex, 
              fragList, 
              bvhNodeId
          );

          bvhNode.addTask(newTask);

          // When we have added the first task to the node, we add it to the consolidation task queue
          if (bvhNode.remainingTasks.length === 1) {
            OutOfCoreTileManager.#bvhNodesPendingQueue.push(bvhNode);
          }
        }
      }
        
      let geometry = consolidation.meshes[meshIndex].geometry;
      // should the geometry be rendered via hardware instancing?
      if (geometry.numInstances > 1) {
        // Is there already a InstancedMeshUploadTask for this meshIndex?
        let instancedMeshUploadTask = bvhNode.getInstancedMeshUploadTask();

        // If not create the task and add it to the node
        if (!instancedMeshUploadTask) {
          instancedMeshUploadTask = new InstancedMeshUploadTask(
            this,
            meshIndex,
            fragList,
            bvhNodeId
          );

          bvhNode.addTask(instancedMeshUploadTask);

          // When we have added the first task to the node, we add it to the consolidation task queue
          if (bvhNode.remainingTasks.length === 1) {
            OutOfCoreTileManager.#bvhNodesPendingQueue.push(bvhNode);
          }
        } else {
          // Otherwise add the mesh to the existing task
          instancedMeshUploadTask.addGeometry(meshIndex);
        }
      }
    }

    /**
     * Tries to initialize a node. 
     * 
     * This will create all tasks that are needed to process the node. A node can only be initialized if all required
     * geometries are available. Otherwise, the initialization will fail and the function will return false.
     * If the node has already been initialized, the function is idempotent and will return true.
     * 
     * @param {number} bvhNodeId - The ID of the BVH node
     * @returns {boolean}        - True if the node has been initialized, false otherwise
     */
    tryInitializeNode(bvhNodeId) {
      if (this.bvhNodeInitialized(bvhNodeId)) {
        return true;
      }

      const consolidation = this.model.getConsolidation();
      const fragList = this.model.getFragmentList();
      const bvhModelIterator = this.model.getIterator();
      let node = this.getBvhNode(bvhNodeId);

      if (!(bvhModelIterator instanceof ModelIteratorBVH) || !consolidation) {
        return false;
      }
  
      const renderBatch = bvhModelIterator.getGeomScenes()[bvhNodeId];
      if (!renderBatch) {
        return false;
      }
  
      let renderBatchIndices = renderBatch.getIndices();
  
      let tileCompletelyLoaded = true;
  
      // Make sure, we don't have any missing fragments in the consolidation.
      let lastValidMeshIndex;
      for (let i = renderBatch.start; i < renderBatch.lastItem; i++) {
  
        let fragId = renderBatchIndices[i];
  
        // find consolidated shape containing this fragment
        let meshIndex = consolidation.fragId2MeshIndex[fragId];
        // For all other error cases (smaller error numbers), we mark the consolidation
        // as not yet ready
        if (meshIndex < NO_MESH_FOR_FRAGMENT) {
  
          if (!node.allRequestsAdded) {
            if (meshIndex === MESH_STILL_PENDING) {
              this.addPendingGeometry(node, fragId);
            } else {
              let rangeIndex = -meshIndex + MIN_VALUE_FOR_PENDING_RANGE;
              this.addPendingRange(node, rangeIndex);
            }
          }
  
          tileCompletelyLoaded = false;
        }
  
        // If there is no assigned mesh for the fragment (e.g. because it has no/a failed associated geometry),
        // we just ignore it
        if (meshIndex === NO_MESH_FOR_FRAGMENT) {
          continue;
        }
        lastValidMeshIndex = meshIndex;
      }
      node.allRequestsAdded = true;
  
      if (!tileCompletelyLoaded || lastValidMeshIndex === undefined) {
        return false;
      }
  
      // Initialize the node
      node.transparent = bvhModelIterator.isNodeTransparent(bvhNodeId);
      node.initialized = true;
  
      // Add tasks for all meshes in the render batch
      for (let i = renderBatch.start; i < renderBatch.lastItem; i++) {
        let fragId = renderBatchIndices[i];
        let meshIndex = consolidation.fragId2MeshIndex[fragId];
        if (meshIndex === -1) {
          continue;
        }
        if (!consolidation.meshes[meshIndex].geometry) {
          continue;
        }
        if (node.transparent) {
          continue;
        }
  
        this.addTask(bvhNodeId, meshIndex, fragList);
      }
  
      // Add tasks for all remaining fragments
      const indices = consolidation.nodeId2SingleFragIds && consolidation.nodeId2SingleFragIds[bvhNodeId];
      if (indices && indices.length > 0) {
        this.setRemainingFragmentsForNode(bvhNodeId, fragList, indices);
      }

      return true;
    };

    /**
     * Releases an iterator, if it is no longer used (when the consolidation iterator that
     * references it is being destroyed or the model is removed from the viewer)
     * @param {number} iteratorId 
     */
    releaseIterator(iteratorId) {
      this.resetScreenSpaceErrors(iteratorId);
      this.resetLockedTiles(iteratorId);
      this.iteratorIdRegistry[iteratorId] = undefined;

      if (this.iteratorIdRegistry.every(id => id === undefined)) {
        this.freeAllNodes();
      }
    }

    /**
     * Reactivate the iterator at the given iterator ID (when a consolidation iterator is being recreated)
     * @param {number} iteratorId 
     * @param {ModelIteratorBVH} iterator 
     */
    activateIterator(iteratorId, iterator) {
      this.iteratorIdRegistry[iteratorId] = iterator;
    }

    /**
     * Frees the memory for all nodes in the OutOfCoreTileManager
     * @param {number} iteratorId - The ID of the iterator
     */
   freeAllNodes() {
      this.bvhNodes.forEach(node => { // forEach skips empty slots
        // Free memory used by the node
        let freedMemory = node.freeMemory();
        OutOfCoreTileManager.#memoryConsumed -= freedMemory;
        node.remainingTasks.splice(0, node.remainingTasks.length);
        node.initialized = false;
      });

      // Remove the node from the working set and the consolidation queue
      OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu = OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.filter(node => node.outOfCoreTileManager !== this);
      OutOfCoreTileManager.#bvhNodesPendingQueue = OutOfCoreTileManager.#bvhNodesPendingQueue.filter(node => node.outOfCoreTileManager !== this);
    }

    /**
     * Retrieves the consolidation mesh for a given iterator ID, BVH node ID, mesh index, fragment list, draw mode, and special handling.
     * @param {number} iteratorId - The iterator ID.
     * @param {number} bvhNodeId - The BVH node ID.
     * @param {number} meshIndex - Index of consolidate/instanced mesh
     * @param {FragmentList} fragList - Fragment list for the model
     * @param {number} drawMode - Render pass id from RenderFlags.
     * @param {Bool} specialHandling - True if the mesh needs special handling
     * 
     * @returns {THREE.Mesh|null} The consolidation mesh if available, otherwise null.
     */
    getConsolidationMesh(iteratorId, bvhNodeId, meshIndex, fragList, drawMode, specialHandling) {
      const consolidation = this.model.getConsolidation();

      if (!consolidation) {
          return null;
      }

      let bvhNode = this.getBvhNode(bvhNodeId);
      bvhNode.updateLastRendered(iteratorId, this.getFrameCount(iteratorId));

      // Check if there is already a consolidated mesh for this meshIndex
      if (!consolidation.meshGeometryAvailable(meshIndex)) {
        // Return null to indicate that the mesh is not yet consolidated
        return null;
      } 
      
      let geometry = consolidation.meshes[meshIndex].geometry;
      // If the mesh is instanced, we need to make sure that the instanced geometry has been uploaded
      if (geometry.numInstances > 1 && geometry.streamingDraw === true) {
        return null;
      }

      // If there is a consolidated mesh, apply the attributes and return it
      return consolidation.applyAttributes(meshIndex, fragList, drawMode, specialHandling);
    }

    /**
     * Adds the geometries from the range to the list of geometries to be requested from the server.
     * 
     * @param {BvhNode} bvhNode - The BVH node.
     * @param {number} rangeIndex - The index of the range to expedite.
     * 
     * @returns {null} Returns null if the consolidation is not available.
     */
    addPendingRange(bvhNode, rangeIndex) {
      if (!bvhNode.pendingHashesSubmitted &&
          !bvhNode.pendingRanges.has(rangeIndex)) {
          bvhNode.pendingRanges.add(rangeIndex);

        if (!bvhNode.pendingHashesSubmitted &&
            OutOfCoreTileManager.pendingNodes.indexOf(bvhNode) === -1) {
          OutOfCoreTileManager.pendingNodes.push(bvhNode);
        }
      }
    }

    /**
     * Adds the geometry to the list of geometries to be requested from the server.
     *
     * @param {BvhNode} bvhNode - The BVH node.
     * @param {number} fragId - The ID of the fragment.
     * @returns {null} Returns null if consolidation is not available.
     */
    addPendingGeometry(bvhNode, fragId) {
      let geomId = this.model.loader.svf.fragments.geomDataIndexes[fragId];
      let geomHash = this.model.loader.svf.getGeometryHash(geomId);
      if (!bvhNode.pendingHashesSubmitted) {
        bvhNode.pendingHashes.add(geomHash);

        if (OutOfCoreTileManager.pendingNodes.indexOf(bvhNode) === -1) {
          OutOfCoreTileManager.pendingNodes.push(bvhNode);
        }
      }
    }

    /**
     * Updates the cost and transparency flag of a BVH node.
     *
     * @param {number} iteratorId - The ID of the iterator.
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {number} cost - The cost to update the BVH node with.
     */
    updateBvhNodeScreenSpaceError(iteratorId, bvhNodeId, cost) {
      let node = this.getBvhNode(bvhNodeId);

      node.updateScreenSpaceError(iteratorId, cost, this.getFrameCount(iteratorId));
    }

    /**
     * Sets the remaining fragments for a given BVH node.
     * 
     * @param {number} bvhNodeId - The ID of the BVH node.
     * @param {FragmentList} fragList - Fragment list for the model.
     * @param {number[]} fragmentIds - The IDs of the remaining fragments.
     */
    setRemainingFragmentsForNode(bvhNodeId, fragList, fragmentIds) {
      let bvhNode = this.getBvhNode(bvhNodeId);
      
      const remainingFragmentsTask = bvhNode.getRemainingFragmentsTask();
      if (!remainingFragmentsTask) {
        bvhNode.addTask(new RemainingFragmentsTask(this, fragList, bvhNodeId, fragmentIds));

        // When we have added the first task to the node, we add it to the consolidation task queue
        if (bvhNode.remainingTasks.length === 1) {
          OutOfCoreTileManager.#bvhNodesPendingQueue.push(bvhNode);
        }
      } else {
        // This function should never be invoked twice for the same node and thus
        // there should never be a RemainingFragmentsTask already present
        throw new Error("setRemainingFragmentsForNode invoked twice");
      }
    }

    /**
     * Removes the locked flag from all tiles. This should only be called,
     * once all candidate renderbatches have been cleared and therefore
     * no old consolidated mesh is any longer referenced.
     * 
     * @param {number} iteratorId -- The id of the iterator
     */
    resetLockedTiles(iteratorId) {
      let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
      if (lockedTiles) {
        for (let tileId of lockedTiles) {
          this.unlockTile(iteratorId, tileId);
        }
      }
    }

    /**
     * Unlocks a tile corresponding to the given nodeId
     * @param {number} iteratorId -- The id of the iterator
     * @param {number} bvhNodeId -- The id of the node in the BVH 
     */
    unlockTile(iteratorId, bvhNodeId) {
      let node = this.getBvhNode(bvhNodeId);

      let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
      if (lockedTiles.delete(bvhNodeId)) {
        node.lockedCounter--;
      }
      console.assert(lockedTiles && node.lockedCounter >= 0);
    }

    /**
     * Locks a tile corresponding to the given nodeId. While tiles are locked
     * they cannot be removed from the GPU memory. 
     * The reason this is needed is, that the RenderScene keeps render batches across frames 
     * while doing progressive rendering and we must not free the resources hold by one of these 
     * cached batches. Only after the batch has been rendered or the render scene has been
     * reset can we free the resources.
     *  
     * @param {number} iteratorId -- The id of the iterator
     * @param {number} bvhNodeId -- The id of the node in the BVH 
     */
    lockTile(iteratorId, bvhNodeId) {
      let node = this.getBvhNode(bvhNodeId);
      node.lockedCounter++;

      let lockedTiles = this.iteratorLockedTiles.get(iteratorId);
      if (!lockedTiles) {
        lockedTiles = new Set();
        this.iteratorLockedTiles.set(iteratorId, lockedTiles);
      }
      lockedTiles.add(bvhNodeId);
    }

    /**
     * Increment the frame count
     */
    incrementFrameCount(iteratorId) {
      this.#frameCounts[iteratorId] = (this.#frameCounts[iteratorId] ?? 0) + 1;
    }

    /**
     * Resets the screen space errors in all nodes for the given iterator
     * @param {number} iteratorId - The ID of the iterator
     */
    resetScreenSpaceErrors(iteratorId) {
      // Reset the screen space errors for all nodes
      this.bvhNodes.forEach(node => { // forEach skips empty slots
        node.updateScreenSpaceError(iteratorId, -Infinity, 0);
        node.updateLastRendered(iteratorId, 0);
      });
    }

    /**
     * Obtains the ID for the given model iterator
     * @param {ModelIteratorBVH} iterator 
     * @param {boolean} doNotCreate - Whether to create a new ID if it does not exist
     * @returns 
     */
    getIteratorId(iterator, doNotCreate = false) {
      let ids = this.iteratorIdRegistry;
      
      // Do we already have an entry for the model?
      let index = ids.indexOf(iterator);
      if (index !== -1) {
        return index;
      }

      if (doNotCreate) {
        return undefined;
      }

      // If not, insert the model in the next free slot
      for (let i = 0; true; i++) {
          if (ids[i] === undefined) {
              ids[i] = iterator;
              return i;
          }
      }
    }

    /**
     * Assign a viewer to the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer - The viewer instance to use for this OutOfCoreTileManager
     */
    #addViewer(viewer) {
      this.#viewers.push(viewer);
      this.#renderer = viewer.glrenderer();
    }

    /**
     * Removes a viewer from the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer - The viewer instance to use for this OutOfCoreTileManager
     * @param {RenderModel} model - The model which is being removed
     */
    #removeViewer(viewer, model) {
      let viewerIndex = this.#viewers.indexOf(viewer);
      if (viewerIndex !== -1) {
        this.#viewers.splice(viewerIndex, 1);
      }

      const bvhIterator = model.getIterator();
      const iteratorId = this.iteratorIdRegistry.indexOf(bvhIterator);
      if (iteratorId !== -1) {
        this.releaseIterator(iteratorId);
      }
    }

    /**
     * Returns a viewer instance for this OutOfCoreTileManager. There might be multiple viewers
     * associated to this OutOfCoreTileManager if those instances share resources. In such a case,
     * we return the first viewer instance.
     *  
     * @returns {Viewer3DImpl} - The viewer instance
     */
    getViewer() {
      return this.#viewers[0];
    }

    /**
     * Get the Renderer associated with this OutOfCoreTileManager
     * @returns {WebGLRenderer} - The renderer instance
     */
    getRenderer() {
      return this.#renderer;
    }

    /**
     * Returns the next batch of geometries to be requested from the server.
     * @returns {{model: RenderModel, hashes: string[]}|null} - The model and hashes to be requested or null if none needed
     */
    static getNextBVHNodeToPrefetchGeometryFor() {
      OutOfCoreTileManager.pendingNodes.sort(
        (a, b) => {
          return -a.compare(b);
        }
      );

      let node = OutOfCoreTileManager.pendingNodes.pop();
      if (!node) {
        return null;
      }

      node.pendingHashesSubmitted = true;
      let pendingHashes = node.pendingHashes;

      let model = node.outOfCoreTileManager.model;
      let consolidation = model.getConsolidation();
      if (!consolidation) {
        return null;
      }

      for (let rangeIndex of node.pendingRanges) {
        let geomHashesSet = consolidation.getPendingGeometryHashesForRange(rangeIndex, model);
        for (let geomHash of geomHashesSet) {
          pendingHashes.add(geomHash);
        }
      }
      node.pendingHashes = null;
      node.pendingRanges = null;
      return {
        model,
        hashes: pendingHashes
      };
    }

    /**
     * Adds a task that is not directly related to a specific node to be processed by the OutOfCoreTileManager
     * @param {Function} task 
     */
    static addGlobalTask(task) {
      OutOfCoreTileManager.globalTasks.push(task);
    }

    /**
     * Run consolidation tasks until consolidationTimeRemaining is exhausted
     * @param {Number} processingTimeRemaining - Time remaining for consolidation
     */
    static executeTasks(processingTimeRemaining) {
      let usedTime = 0;

      // I don't really know how to best prioritize global tasks
      // compared to upload tasks yet. So for the moment, I just
      // have a heuristic, that if in the last frame we didn't 
      // have any time to process upload tasks, we will skip
      // global tasks for this frame.
      if (!OutOfCoreTileManager.onlyGlobalTasksExecuted) {
        let startTimestamp = performance.now();
        while (OutOfCoreTileManager.globalTasks.length > 0) {
          let task = OutOfCoreTileManager.globalTasks[0];
          let completed = task(processingTimeRemaining - usedTime);

          if (completed) {
            OutOfCoreTileManager.globalTasks.shift();
          }

          let endTimestamp = performance.now();
          usedTime = endTimestamp - startTimestamp;
          if (usedTime >= processingTimeRemaining) {
            OutOfCoreTileManager.onlyGlobalTasksExecuted = true;
            return;
          }
        }
      }
      OutOfCoreTileManager.onlyGlobalTasksExecuted = false;

      // Don't start processing events if models have been loaded, but not yet added to the viewer
      // The OutOfCoreTileManager for these models have not yet been fully initialized
      if (OutOfCoreTileManager.notYetAddedManagers.size > 0) {
        return;
      }

      OutOfCoreTileManager.#stats.uploadedThisFrame = 0;
      OutOfCoreTileManager.#stats.removedThisFrame = 0;
      
      // If there is a node, for which we are currently processing tasks, we want to keep it in the front of the queue,
      // because we want to finish it before we start processing other nodes
      let currentlyProcessedNode = undefined;
      if (OutOfCoreTileManager.#bvhNodesPendingQueue.length > 0 &&
          OutOfCoreTileManager.#bvhNodesPendingQueue[0].processedTasks.length > 0) {
        currentlyProcessedNode = OutOfCoreTileManager.#bvhNodesPendingQueue.shift();
      }

      // Sort nodes by screen space error in descending order to consolidate and upload most important ones first
      OutOfCoreTileManager.#bvhNodesPendingQueue.sort(
        (a, b) => {
          return a.compare(b);
        }
      );

      // If there was a node that is being processed, put it back in the front of the queue
      if (currentlyProcessedNode) {
        OutOfCoreTileManager.#bvhNodesPendingQueue.unshift(currentlyProcessedNode);
      }


      // Ensure nodes on gpu are sorted with ascending screen space errors to replace least important ones first
      OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.sort(
        (a, b) => {
          return -a.compare(b);
        }
      );

      while (OutOfCoreTileManager.#bvhNodesPendingQueue.length > 0) {
        const nextNode = OutOfCoreTileManager.#bvhNodesPendingQueue[0];

        if (usedTime >= processingTimeRemaining) {
          break;
        }

        let memoryLeft = OutOfCoreTileManager.#memoryLimit - OutOfCoreTileManager.#memoryConsumed;
        
        // The amount of memory left can become negative, if the memory limit is reduced and
        // not all memory could be freed instantly, because of locked nodes. In that case, we
        // free the memory here, without any reference to a comparison node, but we would 
        if (memoryLeft < 0) {
          OutOfCoreTileManager.tryToFreeMemory(null, -memoryLeft);
          memoryLeft = OutOfCoreTileManager.#memoryLimit - OutOfCoreTileManager.#memoryConsumed;
        }
        let requiredMemory = nextNode.getRemainingMemoryCost();

        if (requiredMemory > memoryLeft) {
          this.#trackGPUOutOfCore(nextNode.model);
          // Check whether there is some other geometry we could free to be able to process this task
          if (!OutOfCoreTileManager.tryToFreeMemory(nextNode, requiredMemory)) {
            break;
          }
        }

        while (usedTime < processingTimeRemaining) {
          let [memoryCost, moreTasks, timeTaken] = nextNode.processNextTask();

          usedTime += timeTaken;
          OutOfCoreTileManager.#memoryConsumed += memoryCost;
          
          if (!moreTasks) {
            OutOfCoreTileManager.#stats.uploadedThisSecond++;
            OutOfCoreTileManager.#stats.uploadedThisFrame++;
            OutOfCoreTileManager.#bvhNodesPendingQueue.shift();
            OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.push(nextNode);
            break;
          }
        }
      }

      return usedTime;
    }

    /**
     * Try to free the requested amount of memory by freeing nodes that have a lower screen space error
     * 
     * @param {BvhNode|null} comparisonNode - The node for which we want to free memory and which we are
     *                                        comparing against to determine whether we can free memory.
     *                                        If null, we will free memory until the requested amount is freed
     *                                        not checking for any screen space error.
     * @param {number} requiredMemory - The amount of memory that needs to be freed
     * @returns {boolean} - Whether the requested amount of memory could be freed
     */
    static tryToFreeMemory(comparisonNode, requiredMemory) {
      let freeableMemory = 0;

      let i = 0;
      let memoryCanBeFreed = false;
      let scratchpad = {};
      for (; i < OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.length; i++) {
        let candidateNode = OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu[i];

        if ((candidateNode.lockedCounter === 0) &&
            (comparisonNode === null || comparisonNode.compare(candidateNode) < 0)) {
          freeableMemory += candidateNode.getFreeableMemory(scratchpad);
        } else {
          break;
        }

        if (freeableMemory > requiredMemory) {
          memoryCanBeFreed = true;
          break;
        }
      }
      if (!memoryCanBeFreed) {
        return false;
      } 

      let totalMemoryFreed = 0;
      for (let j = 0; j <= i; j++) {
        let nodeToFree = OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.shift();
        let freedMemory = nodeToFree.freeMemory();
        totalMemoryFreed += freedMemory;
        OutOfCoreTileManager.#memoryConsumed -= freedMemory;
        OutOfCoreTileManager.#bvhNodesPendingQueue.push(nodeToFree);

        OutOfCoreTileManager.#stats.removedThisSecond++;
        OutOfCoreTileManager.#stats.removedThisFrame++;
      }
      console.assert(totalMemoryFreed === freeableMemory);

      return true;
    }

    /**
     * Sets the consolidation memory limit for the OutOfCoreTileManager.
     * 
     * @param {number} value - The new consolidation memory limit value.
     */
    static setMemoryLimit(value) {
      let oldLimit = OutOfCoreTileManager.#memoryLimit;
      OutOfCoreTileManager.#memoryLimit = 0.9 * value;

        if (OutOfCoreTileManager.#memoryLimit < oldLimit) {
          this.tryToFreeMemory(null, oldLimit - OutOfCoreTileManager.#memoryLimit);
        }
    }

    /**
     * Returns the frame count for the specified iterator ID.
     * @param {string} iteratorId - The ID of the iterator.
     * @returns {number} The frame count for the specified iterator ID.
     */
    getFrameCount(iteratorId) {
      return this.#frameCounts[iteratorId] ?? 0;
    }

    /**
     * Get the statistics of the OutOfCoreTileManager.
     * @returns {Object} statistics of the OutOfCoreTileManager
     */
    static getStats() {
      if (!OutOfCoreTileManager.#statsUpdateTimer) {
        OutOfCoreTileManager.#statsUpdateTimer = setInterval(OutOfCoreTileManager.updateStats, 1000);
      }

      return {
        consolidationMemoryConsumed: OutOfCoreTileManager.#memoryConsumed,
        consolidationMemoryLimit: OutOfCoreTileManager.#memoryLimit,
        tilesInQueue: OutOfCoreTileManager.#bvhNodesPendingQueue.length,
        tilesOnGpu: OutOfCoreTileManager.#bvhNodesWorkingSetOnGpu.length,
        uploadedLastSecond: OutOfCoreTileManager.#stats.uploadedLastSecond,
        removedLastSecond: OutOfCoreTileManager.#stats.removedLastSecond,
        uploadedThisFrame: OutOfCoreTileManager.#stats.uploadedThisFrame,
        removedThisFrame: OutOfCoreTileManager.#stats.removedThisFrame,
      };
    }

    static updateStats() {
      OutOfCoreTileManager.#stats.uploadedLastSecond = OutOfCoreTileManager.#stats.uploadedThisSecond;
      OutOfCoreTileManager.#stats.removedLastSecond = OutOfCoreTileManager.#stats.removedThisSecond;
      OutOfCoreTileManager.#stats.uploadedThisSecond = 0;
      OutOfCoreTileManager.#stats.removedThisSecond = 0;
    }

    /**
     * Register a new viewer instance with the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer 
     */
    static registerViewer(viewer) {
      viewer.api.addEventListener(EventTypes.MODEL_ADDED_EVENT, OutOfCoreTileManager.#onModelAdded.bind(undefined, viewer));
      viewer.api.addEventListener(EventTypes.MODEL_REMOVED_EVENT, OutOfCoreTileManager.#onModelRemoved.bind(undefined, viewer));
      viewer.api.addEventListener(EventTypes.VIEWER_VISIBILITY_CHANGED, OutOfCoreTileManager.#onVisibilityChanged.bind(undefined, viewer));
    }

    /**
     * Register a new viewer instance with the OutOfCoreTileManager
     * @param {Viewer3DImpl} viewer 
     */
    static unregisterViewer(viewer) {
      viewer.api.removeEventListener(EventTypes.MODEL_ADDED_EVENT, OutOfCoreTileManager.#onModelAdded.bind(undefined, viewer));
      viewer.api.removeEventListener(EventTypes.MODEL_REMOVED_EVENT, OutOfCoreTileManager.#onModelRemoved.bind(undefined, viewer));
      viewer.api.removeEventListener(EventTypes.VIEWER_VISIBILITY_CHANGED, OutOfCoreTileManager.#onVisibilityChanged.bind(undefined, viewer));
    }

    /**
     * The visibility for a viewer has changed
     * @param {Viewer3DImpl} viewer 
     * @param {{visible: boolean}} event        - The event object
     */
    static #onVisibilityChanged(viewer, event) {
      if (!event.visible) {
        // If the viewer has been hidden, we have to reset the error for
        // all nodes, because the screen space error is not valid anymore
        for (let model of viewer.api.getAllModels()) {
          let [manager, iteratorID] = OutOfCoreTileManager.getManagerForIterator(model.getIterator(), model, true);
          if (manager && iteratorID) {
            manager.resetScreenSpaceErrors(iteratorID);
          }
        }
      }
    }

    /**
     * A model has been added to a viewer
     * @param {Viewer3DImpl} viewer               - The viewer instance
     * @param {{model: RenderModel}} event        - The event object
     */
    static #onModelAdded(viewer, event) {
      let outOfCoreTileManager;

      // First check, whether we already have registered a model with the same leechViewerKey
      // (i.e. whether this model shares resources with a different model). In that case, we
      // we will use the already registered OutOfCoreTileManager for the referenced model
      OutOfCoreTileManager._updateLeechViewerKeysInModelRegistry();
      if (event.model.leechViewerKey !== undefined) {
        outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model.leechViewerKey);
      }

      // Check, whether the model has already directly been registered with the out-of-core manager
      if (!outOfCoreTileManager) {
        outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model);
      }
      OutOfCoreTileManager.notYetAddedManagers.delete(outOfCoreTileManager);

      // If we did not find any manager, we create a new one
      if (!outOfCoreTileManager) {
        outOfCoreTileManager = new OutOfCoreTileManager(event.model);

        // If this model is using a LeechViewer, we also store a reference with the leechViewerKey
        if (event.model.leechViewerKey !== undefined) {
          OutOfCoreTileManager.modelManagerRegistry.set(event.model.leechViewerKey, outOfCoreTileManager);
        }
      }
      OutOfCoreTileManager.modelManagerRegistry.set(event.model, outOfCoreTileManager);

      OutOfCoreTileManager.#t1_GPUUpload = undefined;

      // Assign the the viewer to the out-of-core tile manager
      outOfCoreTileManager.#addViewer(viewer);
    }

    /**
     * A model has been removed from a viewer
     * @param {Viewer3DImpl} viewer               - The viewer instance
     * @param {{model: RenderModel}} event        - The event object
     */
    static #onModelRemoved(viewer, event) {
      let outOfCoreTileManager = OutOfCoreTileManager.modelManagerRegistry.get(event.model);
      if (outOfCoreTileManager) {
        outOfCoreTileManager.#removeViewer(viewer, event.model);
      }

      OutOfCoreTileManager.modelManagerRegistry.delete(event.model);

      if (event.model.leechViewerKey !== undefined) {
        let usageCount = Array.from(OutOfCoreTileManager.modelManagerRegistry.keys().filter(x => x.leechViewerKey === event.model.leechViewerKey)).length;
        
        // If we no longer have any reference to the leechViewerKey, we can also remove that entry
        if (usageCount === 0) {
          OutOfCoreTileManager.modelManagerRegistry.delete(event.model.leechViewerKey);
        }
      }
    }

    /**
     * Gets the OutOfCoreTileManager and the iteratorId for an iterator
     * @param {ModelIteratorBVH} iterator - The BVH iterator
     * @param {RenderModel} model - The model the BVH iterator is responsible for
     * @param {boolean} doNotCreate - Whether to create a new manager if none is found
     * @return {[OutOfCoreTileManager, number]}
     */
    static getManagerForIterator(iterator, model, doNotCreate = false) {
      OutOfCoreTileManager._updateLeechViewerKeysInModelRegistry();
      let  manager = OutOfCoreTileManager.modelManagerRegistry.get(model.leechViewerKey) ?? 
                     OutOfCoreTileManager.modelManagerRegistry.get(model);

      if (!manager) {
        if (doNotCreate) {
          return [undefined, 0];
        } else {
          // Create a new manager if none yet exists (this can happen if the 
          // BVH is created before the model is added to the viewer)
          manager = new OutOfCoreTileManager(model);

          // If this model is using a LeechViewer, we also store a reference with the leechViewerKey
          if (model.leechViewerKey !== undefined) {
            OutOfCoreTileManager.modelManagerRegistry.set(model.leechViewerKey, manager);
          }
          OutOfCoreTileManager.modelManagerRegistry.set(model, manager);
          OutOfCoreTileManager.notYetAddedManagers.add(manager);
        }
      }
      
      let id = manager.getIteratorId(iterator, doNotCreate);

      return [manager, id];
    }

    /**
   * Updates the leechViewerKeys in the model registry
   * 
   * It can happen that models are added to the registry, before their leechViewerKey is set. In that case, we
   * would not have them under the correct key in the registry. This function updates the registry to ensure that
   * all models are registered under the correct key.
   */
  static _updateLeechViewerKeysInModelRegistry() {
    for (let [existingModel, outOfCoreTileManager] of OutOfCoreTileManager.modelManagerRegistry.entries()) {
      if (existingModel.leechViewerKey && !OutOfCoreTileManager.modelManagerRegistry.has(existingModel.leechViewerKey)) {
        OutOfCoreTileManager.modelManagerRegistry.set(existingModel.leechViewerKey, outOfCoreTileManager);
      }
    }
  }

    /**
     * Free all GPU Ressources in the case of a context loss.
     */
    static resetAfterContextLoss() {
      for (let manager of OutOfCoreTileManager.modelManagerRegistry.values()) {
        manager.freeAllNodes();
      }
    }

  /**
   * Add analytics for GPU out-of-core, including:
   * - Time to reach the GPU memory limit the first time, if it was reached at all
   *
   * Note the OutOfCoreTileManager operates on multiple models, so there is no specific URL attached to the event.
   *
   * @param {RenderModel} [model]
   */
  static #trackGPUOutOfCore(model) {
    // only track the first time
    if (OutOfCoreTileManager.#t1_GPUUpload) {
      return;
    }

    this.#t1_GPUUpload = performance.now();
    const stats = OutOfCoreTileManager.getStats();
    const properties = {
      memory_limit_time: this.#t1_GPUUpload - this.#t0_ModelCreation,
      consolidation_memory_consumed: stats.consolidationMemoryConsumed,
      consolidation_memory_limit: stats.consolidationMemoryLimit,
      tiles_in_queue: stats.tilesInQueue,
      tiles_on_gpu: stats.tilesOnGpu,
      urn: model?.getData()?.urn ?? 'NOT_SET',
    };
    analytics.track('viewer.model.gpu_out_of_core', properties);
  }

  static setModelLoadStartedTimestamp() {
    this.#t0_ModelCreation = performance.now();
  }
}

// Ressource manager is currently not yet merged in dev
/*
OutOfCoreTileManager.setMemoryLimit(ResourceManager.getHardwareLimits().GPU_MEMORY_LIMIT);

// Register listener for hardware level changes to update consolidation memory limit
ResourceManager.addEventListener(et.HARDWARE_LEVEL_CHANGED, function() {
  OutOfCoreTileManager.setMemoryLimit(ResourceManager.getHardwareLimits().GPU_MEMORY_LIMIT);
});
*/
