import {VertexBuffer} from "./VertexBuffer";
import {BlendPass} from "./post/BlendPass";
import {MainPass} from "./main/MainPass";
import {GradientPass} from "./clear/GradientPass";
import {EnvMapPass} from "./clear/EnvMapPass";
import {SAOPass} from "./ssao/SAOPass";
import {GroundShadowPass} from "./ground/GroundShadowPass";
import {CommonRenderTargets} from "./CommonRenderTargets";
import {ObjectUniforms} from "./main/ObjectUniforms";
import {getMaterialTextureMask, setMaterialTextureUpdateCallback, MaterialUniformFlags} from "./main/MaterialUniforms";

const Events = {
	WEBGPU_DEVICE_LOST: 'webgpudevicelost',
	WEBGPU_INIT_FAILED: 'webgpuinitfailed',
	WEBGPU_RENDER_DONE: 'webgpurenderdone',
};

// TODO: Increasing this value leads to significantly faster rendering for many models,
// but it also introduces input delay and affects overall smoothness.
// I've set it to a low value for now and need to investigate more later.
const commandSubmitThreshold = 3;

export function Renderer(device, params) {

	let _gpu;
	let _device = device;
	let _canvas;
	let _initDone;
	let _pixelRatio;

	let _vb;
	let _presentationFormat;
	let _objectUniforms;
	let _commandGroups = [];
	let _models = new Set();
	let _modelBundlesInvalidated = new Map();
	let _modelVisibilityListener = new Map();

	let _renderTargets = new CommonRenderTargets(this);
	let _mainPass = new MainPass(this);
	let _postPass = new BlendPass(this);
	let _gradientPass = new GradientPass(this);
	let _envMapPass = new EnvMapPass(this);
	let _sao = new SAOPass(this);
	let _groundShadowPass = new GroundShadowPass(this);

    /**
     * @typedef {Object} TextureInfo
     * @property {GPUTexture} texture
     * @property {GPUTextureView} view
     * @property {GPUSampler} sampler
     */
	/** @type {TextureInfo} */
	let _placeholderTexture;

	_canvas = params.canvas || document.createElement("canvas");
	_gpu = _canvas.getContext("webgpu");
	_pixelRatio = params.pixelRatio;

	this.context = _gpu;

	Autodesk.Viewing.EventDispatcher.prototype.apply(this);

	// Register a material update callback that invalidates render bundles if the textures of a material changed.
	setMaterialTextureUpdateCallback((event) => {
		const material = event.target;
		const materialTextureMask = material.__gpuUniformsMask & MaterialUniformFlags.TEXTURE_MASK;
		const newMaterialTextureMask = getMaterialTextureMask(material);
		if (materialTextureMask !== newMaterialTextureMask) {
			// Note that this might be called quite often while loading the model.
			// Up to number of textures * number of materials that use the texture.
			// It would be better to disable render bundles while loading large textured models altogether,
			// but determining this and re-enabling render bundles after loading seems complex and invasive.
			// We might still want to improve things, e.g. by using a heuristic that deactivates bundles if
			// this gets called too often, and enables them again if they haven't been invalidated for some
			// time or number of frames.
			this.invalidateRenderBundles();
		}
	});


	// If the device is already available, we can initialize synchronously
	this.initSync = function(targetWidth, targetHeight) {

		_objectUniforms = new ObjectUniforms(this);

		_presentationFormat = navigator.gpu.getPreferredCanvasFormat();

		_gpu.configure({
			device: _device,
			format: _presentationFormat,
			alphaMode: "premultiplied",
		});

		_initDone = true;

		//Order matters below

		initPlaceholderTexture();

		_vb = new VertexBuffer(this);

		_renderTargets.init();
		_mainPass.init(_objectUniforms);
		_sao.init();
		_postPass.init();
		_groundShadowPass.init(_objectUniforms);

		this.setSize(targetWidth, targetHeight);

		_gradientPass.init();
		_envMapPass.init();

		_device.lost.then((info) => {
			console.error(`WebGPU device was lost: ${info.message}`);

			_device = null;

			// Please also note there is no "restore" event
			this.fireEvent({ type: Events.WEBGPU_DEVICE_LOST });
		});
	};

	function modelVisibilityDirtyCallback(model, renderer) {
		if (!this.visibilityDirty) {
			this.visibilityDirty = true;

			renderer.invalidateRenderBundles(model);
		}
	}

	this.clearModelVisibilityDirty = function(modelId) {
		_modelVisibilityListener.get(modelId).visibilityDirty = false;
	};

	this.addModel = function(model) {
		_models.add(model);
		_objectUniforms.addModel(model);

		const listener = { visibilityDirty: false };
		const callback = modelVisibilityDirtyCallback.bind(listener, model, this);
		listener.callback = callback;
			model.getFragmentList().registerVisibilityDirtyCallback(callback);
		_modelVisibilityListener.set(model.id, listener);
	};

	this.removeModel = function(model) {
		_models.delete(model);
		_objectUniforms.removeModel(model);

		const callback = _modelVisibilityListener.get(model.id).callback;
			model.getFragmentList().removeVisibilityDirtyCallback(callback);
		_modelVisibilityListener.delete(model.id);

		this.invalidateRenderBundles(model);

			// Reset render batch state
			const batches = model.getIterator().getGeomScenes();
			for (const batch of batches) {
				if (batch) {
					batch.isComplete = false;
					batch.useRenderBundles = false;
				}
			}
	};

	function initPlaceholderTexture() {

		//Texture we bind to unused slots in bind groups that are reused with multiple configurations
		let texture  = _device.createTexture({
			label: 'placeholder',
			dimension: "2d",
			format: "bgra8unorm",
			size: [4, 4],
			usage: GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.COPY_DST
		});

		let view = texture.createView();
		let sampler = _device.createSampler({
			addressModeU: "repeat",
			addressModeV: "repeat",
			//maxAnisotropy: 16,
			//magFilter: "linear",
			//minFilter: "linear",
			//mipmapFilter: "linear"
		});

		_placeholderTexture = {
			texture, sampler, view
		};

		//Winner of most annoying looking texture in the world for 2016
		const data = new Uint32Array(16);
		const w = 0xffffffff; const r = 0xffff0000;
		data[0]  = r; data[1]  = w; data[2]  = w; data[3]  = r;
		data[4]  = r; data[5]  = w; data[6]  = r; data[7]  = r;
		data[8]  = r; data[9]  = r; data[10] = w; data[11] = r;
		data[12] = w; data[13] = w; data[14] = r; data[15] = w;

		_device.queue.writeTexture({ texture },
								data,
								{ offset: 0, bytesPerRow: 16 },
								[ 4, 4 ]
								);
	}

	this.getPixelRatio = function () {
		return _pixelRatio || window?.devicePixelRatio || 1;
	};
	this.setPixelRatio = function ( value ) {
		_pixelRatio = value;
	};

	this.setSize = function ( width, height, updateStyle ) {

		_canvas.width = width * this.getPixelRatio();
		_canvas.height = height * this.getPixelRatio();

		if ( updateStyle !== false ) {

			_canvas.style.width = width + 'px';
			_canvas.style.height = height + 'px';
		}

		//This one needs to be first
		_renderTargets.resize(_canvas.width, _canvas.height);

		_mainPass.resize(_canvas.width, _canvas.height);
		_sao.resize(_canvas.width, _canvas.height);
		_postPass.resize(_canvas.width, _canvas.height);
	};


	this.renderBackground = function(useEnvMap) {

		if (useEnvMap && _envMapPass.hasCubeMap()) {
			_envMapPass.run();
		} else {
			_gradientPass.run();
		}

	};

	this.present = function(antialias, camera, waitForDone, userFinalPass = null) {

		if (!_initDone) {
			this.fireEvent({ type: Events.WEBGPU_RENDER_DONE });
			return;
		}

		if (!userFinalPass) {
			// Default way: PostPass => finalTarget
			_postPass.run(_gpu.getCurrentTexture().createView(), antialias, camera);
		} else {
			// render blendPass into post1
			const post1 = _renderTargets.getPostTarget(1);
			_postPass.run(post1.createView(), antialias, camera);

			// render userFinalPass from post1 into finalTarget
			userFinalPass.run(_gpu.getCurrentTexture(), post1);
		}

		if (waitForDone) {
			// Inform listeners as soon as all currently submitted commands are done.
			_device.queue.onSubmittedWorkDone().then(() => {
				this.fireEvent({ type: Events.WEBGPU_RENDER_DONE });
			});
	}
	};

	this.beginScene = function(camera, lights) {

		if (!_initDone) return;

		_modelBundlesInvalidated.clear();

		_mainPass.beginScene(camera, lights);
	};

	//TODO: needClear and updateLights are bogus
	this.renderScenePart = function( scene, showEdges ) {

		if (!_initDone) return;

		const commandGroup = _mainPass.renderScenePart(scene, showEdges);
		if (commandGroup) {
			_commandGroups.push(commandGroup);
			if (_commandGroups.length >= commandSubmitThreshold) {
				_device.queue.submit(_commandGroups);
				_commandGroups.length = 0;
			}
		}
	};

	this.renderOverlay = function( scene, camera, materialPre, materialPost, showEdges, customEdgeColor, lights) {

		if (!_initDone) return;

		_mainPass.renderOverlay(scene, camera, materialPre, materialPost, showEdges, customEdgeColor, lights);
	};

	this.flushCommandQueue = function() {
		if (_commandGroups.length) {
			_device.queue.submit(_commandGroups);
			_commandGroups.length = 0;
		}
	};

	/**
	 * @returns {GPUDevice}
	 */
	this.getDevice = function() {
		return _device;
	};

	this.getRenderTargets = function() {
		return _renderTargets;
	};

	/** @returns {VertexBuffer} */
	this.getVB = function() {
		return _vb;
	};

	this.getGradientPass = function() {
		return _gradientPass;
	};

	this.getEnvMapPass = function() {
		return _envMapPass;
	};

	this.getIBL = function() {
		return _mainPass.getIBL();
	};

	this.getSAO = function() {
		return _sao;
	};

	this.getMainPass = function() {
		return _mainPass;
	};

	this.getGroundShadowPass = function() {
		return _groundShadowPass;
	};

	this.getPostPass = function() {
		return _postPass;
	}

	this.getBlendSettings = function() {
		return _postPass.getBlendSettings();
	};

	this.setRenderTarget = function(target) {
		//console.log("deprecated setRenderTarget");
	};

	this.clearTarget = function(target) {
		//TODO: this is to be removed
	};
	this.clearMainTargets = function() {
		//TODO: we really only needs this clear if there is no initial scene to draw
		//from the beginScene() call of RenderContext, otherwise it can be
		//done via implicit clear with that scene
		_initDone && _mainPass.clearMainTargets();
	};
	this.clearOverlayTargets = function() {
		//TODO: we only need this explicit clear if there aren't
		//any overlays to draw -- otherwise the implicit clear
		//during the pass that draws the overlays can do it.
		_initDone && _mainPass.clearOverlayTargets();
	};

	this.clear = function() {

	};
	this.depthFunc = function() {

	};

	this.updateTimestamp = function(ts) {

	};

	this.getMaxAnisotropy = function() {
		return 16;
	};

	this.supportsMRT = function() { return true; };
	this.verifyMRTWorks = function() { return true; };

	this.cleanup = function() {
		//TODO: destroy all targets/buffers
		//destroy passes that own targets/other resources
	};

	/** @returns {TextureInfo} */
	this.getPlaceholderTexture = function() {
		return _placeholderTexture;
	};

	this.stats = function() {
		_vb.stats();
	};

	this.setLineStyleBuffer = function(buffer, width) {
		_mainPass.setLineStyleBuffer(buffer, width);
	};

	this.invalidateRenderBundles = function(model) {
		let models;
		if (model) {
			models = [model];
		} else {
			models = _models;
		}

		models.forEach((model) => {
			if (!_modelBundlesInvalidated.has(model.id)) {
				const scenes = model.getIterator().getGeomScenes();
					for (const scene of scenes) {
						if (scene) {
							scene.clearRenderBundles();
						}
					}
				_modelBundlesInvalidated.set(model.id);
			}
		});
	};

	this.getContext = function () {
		return this.context;
    };
}

Renderer.Events = Events;
