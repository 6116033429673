import { resetGlobalArrayBuffer, createConsolidatedGeometry } from '../../consolidation/Consolidation';
import { USE_TRANSFORM_FEEDBACK_FOR_CONSOLIDATION } from '../../../globals';

/** @import { OutOfCoreTileManager } from "../OutOfCoreTileManager" */
/** @import { OutOfCoreTask } from "../OutOfCoreTileManager" */
/** @import { FragmentList } from "../../FragmentList" */

/**
 * This task represents the consolidation of a mesh
 * 
 * @implements OutOfCoreTask
 */
export class ConsolidationTask {
  /** @type {number|undefined} */ #memoryCost;

  /**
   * Creates a new ConsolidationTask
   *
   * @param {OutOfCoreTileManager} OutOfCoreTileManager - The OutOfCoreTileManager instance
   * @param {number} meshIndex - The index of the mesh
   * @param {FragmentList} fragList - The fragment list
   * @param {number} bvhNodeId - The BVH node ID
   */
  constructor(outOfCoreTileManager, meshIndex, fragList, bvhNodeId) {
    this.outOfCoreTileManager = outOfCoreTileManager;
    this.meshIndex = meshIndex;
    this.fragList = fragList;
    this.bvhNodeId = bvhNodeId;
  }

  /**
   * Executes the task
   * 
   * @returns {number|undefined} The memory consumed when executing the task, undefined if no consolidation is available
   */
  execute() {
    const model = this.outOfCoreTileManager.model;
    const consolidation = model.getConsolidation();
    if (!consolidation) {
      return;
    }
    const renderer = this.outOfCoreTileManager.getRenderer();

    // Firefox on macOs doesn't support transform feedback currently
    const useTransformFeedback = Autodesk.Viewing.Private.isFirefox() && Autodesk.Viewing.Private.isMac() ? false : USE_TRANSFORM_FEEDBACK_FOR_CONSOLIDATION;
    const geometry = createConsolidatedGeometry(consolidation, this.meshIndex, this.fragList, useTransformFeedback, renderer);

    renderer.uploadGeometry(geometry);

    resetGlobalArrayBuffer();

    return this.getMemoryCost();
  }

  /**
   * Frees the memory consumed by the task
   * 
   * @returns {number} The memory that could be freed
   */
  freeMemory() {
    const consolidation = this.outOfCoreTileManager.model.getConsolidation();
    if (!consolidation) {
      return 0;
    }
    consolidation.freeMeshGeometry(this.meshIndex);
    return this.getMemoryCost();
  }

  /**
   * Returns the memory cost of the task
   * @returns {number} The memory cost of the task
   */
  getMemoryCost() {
    if (this.#memoryCost === undefined) {
      const consolidation = this.outOfCoreTileManager.model.getConsolidation();
      if (!consolidation) {
        return 0;
      }
      this.#memoryCost = consolidation.getMemoryCostForConsolidatedMesh(this.meshIndex, this.fragList);
    }

    return this.#memoryCost;
  }

  /**
   * Returns the memory that can be freed by this task
   * @param {Object} scratchpad - Used to share information with other tasks to accurately determine the memory that can be freed
   * @returns
   */
  getFreeableMemory(scratchpad) {
    return this.getMemoryCost();
  }
}
