import { digest } from './file-loaders/lmvtk/otg/HashStrings.js';

/**
 * Returns the Client ID, given the access token.
 * @param {String} accessToken 
 * @returns {Promise<String>} - a promise that resolves to a valid Client ID or 'NOT_SET'
 */
export async function getClientId(accessToken) {
  if (!accessToken) {
    return 'NOT_SET';
  }
  
  const base64Url = accessToken.split('.')[1];
  const jsonPayload = Autodesk.Viewing.fromUrlSafeBase64(base64Url);
  const json = JSON.parse(jsonPayload);

  // only accept hashed client_id
  const clientId = json.client_id;
  if (clientId) {
    const maskedClientId = await digest('SHA-256', clientId, true);
    return maskedClientId;
  }

  return 'NOT_SET';
};