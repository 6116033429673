import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getObjectUniformsDeclaration} from "./ObjectUniforms";
import ghosting from "../chunks/ghosting.wgsl";
import {getMaterialUniformsDeclaration} from "./MaterialUniforms";
import edgeShader from "./edge.wgsl";
import {FrameBindGroup} from "./FrameBindGroup";


export function getEdgeShader(ghosted = false) {
	return $wgsl(edgeShader, {
		ghosting,
		ghosted,
		frameBindGroup: FrameBindGroup.getDeclaration(0),
		objectUniforms: getObjectUniformsDeclaration(1),
		materialUniforms: getMaterialUniformsDeclaration(2)
	});
}
