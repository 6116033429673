import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getObjectUniformsDeclaration} from "../main/ObjectUniforms";
import {getLineUniformsDeclaration} from "./LineUniforms";
import {LineMaterialManager} from "./LineMaterialManager";
import hatch from "../chunks/hatch_pattern.wgsl";
import lineShader from "./lineSS.wgsl";
import {FrameBindGroup} from "../main/FrameBindGroup";

export function getLineShaderSS(material) {
	return $wgsl(lineShader, {
		frameBindGroup: FrameBindGroup.getDeclaration(0),
		objectUniforms: getObjectUniformsDeclaration(1),
		lineUniforms: getLineUniformsDeclaration(2),
		lineMaterialUniforms: LineMaterialManager.getUniformsDeclaration(3),
		hatch,
		materialHasLineStyles: material.hasLineStyles,
		isSelectionHighlight: material.defines["SELECTION_RENDERER"]
	});
}
